var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Link = function (props) { return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16" }, props),
    React.createElement("path", { d: "M7.21 11.92l-1.16 1.16a2.48 2.48 0 01-3.5-3.5l2-2A2.47 2.47 0 015.47 7a2.31 2.31 0 011-.15 2.28 2.28 0 011 .3 2.61 2.61 0 01.8.68.92.92 0 001.35.17.93.93 0 00.18-1.3 4.26 4.26 0 00-1.39-1.21A4.36 4.36 0 006.64 5a4.18 4.18 0 00-1.82.26 4.24 4.24 0 00-1.55 1l-2 2a4.33 4.33 0 006.12 6.12l1.17-1.17a.92.92 0 000-1.31.93.93 0 00-1.35.02z", fill: "currentColor" }),
    React.createElement("path", { d: "M14.73 1.64a4.32 4.32 0 00-6.07 0L7.48 2.76a.92.92 0 000 1.31.93.93 0 001.32 0L10 2.92a2.48 2.48 0 013.5 3.5l-2 2a2.47 2.47 0 01-.89.57 2.31 2.31 0 01-1 .15 2.41 2.41 0 01-1-.3 2.61 2.61 0 01-.8-.68A.92.92 0 006.38 8a.93.93 0 00-.18 1.3 4.26 4.26 0 001.39 1.21 4.36 4.36 0 001.77.49 4.18 4.18 0 001.82-.26 4.24 4.24 0 001.55-1l2-2a4.32 4.32 0 000-6.07z", fill: "currentColor" }))); };
