var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
var Timeline = styled.ol(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0;\n"], ["\n  padding: 0;\n"])));
var TimelineListItem = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n"], ["\n  display: flex;\n  position: relative;\n"])));
var TimelineSeparator = styled.div(function (_a) {
    var _b = _a.theme, space = _b.space, timeline = _b.timeline;
    return "\n    position: absolute;\n    left: 50%;\n    top: 0;\n    width: ".concat(timeline.separator.width, ";\n    transform: translateX(-50%);\n    background: ").concat(timeline.separator.color, ";\n    height: 100%;\n    display: block;\n    // separator is lowered slightly to improve overlap with nodes.\n    margin-top: ").concat(space[1], ";\n\n    ").concat(TimelineListItem, ":last-child & {\n      display: none;\n    }\n  }\n  ");
});
var TimelineNode = styled.div(function (_a) {
    var _b = _a.theme, timeline = _b.timeline, mediaQueries = _b.mediaQueries;
    return "\n    position: relative;\n    margin-right: ".concat(timeline.gapHorizontal[0], ";\n    padding-bottom: ").concat(timeline.gapVertical[0], ";\n\n    ").concat(mediaQueries.smallAndUp, " {\n      padding-bottom: ").concat(timeline.gapVertical[1], ";\n      margin-right: ").concat(timeline.gapHorizontal[1], ";\n    }\n\n    ").concat(TimelineListItem, ":last-child & {\n      padding-bottom: 0;\n    }\n");
});
var TimelineDot = styled.div(function (_a) {
    var _b = _a.theme, timeline = _b.timeline, mediaQueries = _b.mediaQueries;
    return "\n    position: relative;\n    width: ".concat(timeline.node.size[0], ";\n    height: ").concat(timeline.node.size[0], ";\n    background: ").concat(timeline.node.color, ";\n    border-radius: 50%;\n    overflow: hidden;\n    flex-shrink: 0;\n\n    ").concat(mediaQueries.smallAndUp, " {\n      width: ").concat(timeline.node.size[1], ";\n      height: ").concat(timeline.node.size[1], ";\n    }\n");
});
var TimelineItemContent = styled.div(function (_a) {
    var _b = _a.theme, timeline = _b.timeline, mediaQueries = _b.mediaQueries;
    return "\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    flex: 1;\n    padding-bottom: ".concat(timeline.gapVertical[0], ";\n\n    ").concat(mediaQueries.smallAndUp, " {\n      padding-bottom: ").concat(timeline.gapVertical[1], ";\n    }\n\n    ").concat(TimelineListItem, ":last-child & {\n      padding-bottom: 0;\n    }\n");
});
// forward ref
var TimelineItem = function (_a) {
    var children = _a.children, _b = _a.separator, separator = _b === void 0 ? React.createElement(TimelineSeparator, null) : _b, _c = _a.node, node = _c === void 0 ? React.createElement(TimelineDot, null) : _c, rest = __rest(_a, ["children", "separator", "node"]);
    return (React.createElement(TimelineListItem, __assign({}, rest),
        React.createElement(TimelineNode, null,
            separator,
            node),
        React.createElement(TimelineItemContent, null, children)));
};
export { Timeline, TimelineItem, TimelineDot, TimelineSeparator };
var templateObject_1, templateObject_2;
