import React from 'react';
import styled, { css } from 'styled-components';
import { ColorSpot } from './ColorPanel';

const Flex = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    position: absolute;
    align-items: center;
    > * + * {
      margin-left: ${space[2]};
    }
  `,
);

export const ColorFormat = ({ children }: { children: string }) => (
  <Flex>
    <ColorSpot color={children} baseColor="white" />
    <span>{children}</span>
  </Flex>
);
