var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Children, cloneElement, isValidElement, } from 'react';
import { useUniqueId } from '../../hooks/use-unique-id';
import { ErrorText } from '../ErrorText';
import { FormGroup } from '../FormGroup';
import { HintText } from '../HintText';
import { LabelText } from '../LabelText';
import { Stack } from '../Stack';
import { Stretch } from '../Stretch';
export var Field = function (_a) {
    var children = _a.children, label = _a.label, _b = _a.optional, optional = _b === void 0 ? false : _b, hint = _a.hint, error = _a.error, _c = _a.fullWidth, fullWidth = _c === void 0 ? 'small' : _c, _d = _a.characters, characters = _d === void 0 ? 25 : _d, _e = _a.affixWidth, affixWidth = _e === void 0 ? 0 : _e, id = _a.id, rest = __rest(_a, ["children", "label", "optional", "hint", "error", "fullWidth", "characters", "affixWidth", "id"]);
    var _id = id ? id : useUniqueId();
    var hasError = !!error;
    return (React.createElement(FormGroup, { "data-error": hasError || undefined, "data-testid": "nb-field-form-group" },
        React.createElement(Stack, { spaceBetween: 1 },
            React.createElement("label", { htmlFor: _id },
                React.createElement(LabelText, null,
                    label,
                    optional ? ' (optional)' : null),
                hint ? React.createElement(HintText, null, hint) : null,
                error ? React.createElement(ErrorText, null, error) : null),
            React.createElement(Stretch, { defaultWidth: "calc(".concat(Math.ceil(characters * 0.8), "em + ").concat(affixWidth, "px)"), fullWidth: fullWidth }, Children.map(children, function (child) {
                return isValidElement(child)
                    ? cloneElement(child, __assign({ id: _id, 'aria-invalid': hasError || undefined }, rest))
                    : child;
            })))));
};
