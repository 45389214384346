var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Euro = function (props) { return (React.createElement("svg", __assign({ viewBox: "0 0 16 16", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M10.248 13.467c1.576 0 2.904-.615 3.984-1.845L16 13.4a6.626 6.626 0 01-2.557 1.933c-1.008.445-2.123.667-3.345.667-1.235 0-2.386-.237-3.45-.711a7.631 7.631 0 01-2.685-2 7.56 7.56 0 01-1.534-2.978H0V8.756h2.152A9.608 9.608 0 012.13 8c0-.34.008-.593.022-.756H0V5.69h2.429A7.56 7.56 0 013.963 2.71a7.63 7.63 0 012.684-2C7.712.237 8.863 0 10.098 0c1.222 0 2.337.222 3.345.667A6.626 6.626 0 0116 2.6l-1.768 1.756c-1.08-1.215-2.408-1.823-3.984-1.823-1.137 0-2.138.282-3.004.845-.853.563-1.485 1.333-1.896 2.31h6.114v1.556h-6.52A9.333 9.333 0 004.9 8c0 .178.014.43.043.756h6.519v1.555H5.348c.411.978 1.043 1.748 1.896 2.311.866.563 1.867.845 3.004.845z", fill: "currentColor" }))); };
