var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { VisuallyHidden } from '../VisuallyHidden';
var StyledLoadingContent = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var StyledLoadingContentLoading = styled.span(function (_a) {
    var space = _a.theme.space, $loading = _a.$loading, leftAlign = _a.leftAlign;
    return "\n    position: absolute;\n    width: ".concat(space[12], ";\n    height: auto;\n    opacity: ").concat($loading ? 1 : 0, ";\n\n    ").concat(leftAlign
        ? "\n          left: 0;\n          top: 50%;\n          transform: translateY(-50%);\n        "
        : "\n          top: 50%;\n          left: 50%;\n          transform: translate(-50%, -50%);\n        ", "\n");
});
var StyledSpinner = styled.svg(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  height: 100%;\n  width: auto;\n"], ["\n  display: block;\n  height: 100%;\n  width: auto;\n"])));
var StyledLoadingContentContent = styled.span(function (_a) {
    var $loading = _a.$loading;
    return "\n    position: relative;\n    display: inline-block;\n    opacity: ".concat($loading ? 0 : 1, ";\n");
});
var Spinner = function (props) { return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 100 100" }, props),
    React.createElement("path", { fill: "currentColor", d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50" },
        React.createElement("animateTransform", { attributeName: "transform", attributeType: "XML", type: "rotate", dur: "1s", from: "0 50 50", to: "360 50 50", repeatCount: "indefinite" })))); };
export var LoadingContent = forwardRef(function (_a, ref) {
    var children = _a.children, loading = _a.loading, leftAlign = _a.leftAlign, rest = __rest(_a, ["children", "loading", "leftAlign"]);
    return (React.createElement(StyledLoadingContent, __assign({}, rest, { ref: ref }),
        React.createElement(StyledLoadingContentLoading, { "$loading": loading, leftAlign: leftAlign },
            React.createElement(StyledSpinner, { as: Spinner })),
        React.createElement(StyledLoadingContentContent, { "$loading": loading, "aria-hidden": loading ? 'true' : 'false' }, children),
        React.createElement(VisuallyHidden, null, loading ? 'Loading' : null)));
});
var templateObject_1, templateObject_2;
