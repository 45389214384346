var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { remToNumber } from '../../utils';
var Col = styled.div(function (_a) {
    var _b = _a.theme, grid = _b.grid, mediaQueries = _b.mediaQueries, _c = _a.span, span = _c === void 0 ? 12 : _c, small = _a.small, medium = _a.medium, large = _a.large;
    var halfGutter = grid.gutter.map(function (g) { return remToNumber(g) / 2 + 'rem'; });
    var percentage = (span / 12) * 100;
    var smallPercentage = small ? (small / 12) * 100 : null;
    var mediumPercentage = medium ? (medium / 12) * 100 : null;
    var largePercentage = large ? (large / 12) * 100 : null;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      flex: 0 0 auto;\n      flex-basis: ", "%;\n      max-width: ", "%;\n      box-sizing: border-box;\n      padding-left: ", ";\n      padding-right: ", ";\n      margin-bottom: ", ";\n      width: 100%;\n\n      ", " {\n        padding-left: ", ";\n        padding-right: ", ";\n        margin-bottom: ", ";\n\n        ", "\n      }\n\n      ", "\n\n      ", "\n    "], ["\n      flex: 0 0 auto;\n      flex-basis: ", "%;\n      max-width: ", "%;\n      box-sizing: border-box;\n      padding-left: ", ";\n      padding-right: ", ";\n      margin-bottom: ", ";\n      width: 100%;\n\n      ", " {\n        padding-left: ", ";\n        padding-right: ", ";\n        margin-bottom: ", ";\n\n        ", "\n      }\n\n      ", "\n\n      ", "\n    "])), percentage, percentage, halfGutter[0], halfGutter[0], grid.gutter[0], mediaQueries.smallAndUp, halfGutter[1], halfGutter[1], grid.gutter[1], smallPercentage &&
        "\n        flex-basis: ".concat(smallPercentage, "%;\n        max-width: ").concat(smallPercentage, "%;\n      "), mediumPercentage &&
        "\n        ".concat(mediaQueries.mediumAndUp, " {\n            flex-basis: ").concat(mediumPercentage, "%;\n            max-width: ").concat(mediumPercentage, "%;\n        }\n    "), largePercentage &&
        "\n        ".concat(mediaQueries.largeAndUp, " {\n            flex-basis: ").concat(largePercentage, "%;\n            max-width: ").concat(largePercentage, "%;\n        }\n    "));
});
export { Col };
var templateObject_1;
