exports.components = {
  "component---src-pages-components-tsx": () => import("./../../../src/pages/components.tsx" /* webpackChunkName: "component---src-pages-components-tsx" */),
  "component---src-pages-foundations-tsx": () => import("./../../../src/pages/foundations.tsx" /* webpackChunkName: "component---src-pages-foundations-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-guides-tsx": () => import("./../../../src/pages/guides.tsx" /* webpackChunkName: "component---src-pages-guides-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-accordion-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/accordion.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-accordion-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-badge-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/badge.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-badge-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-callout-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/callout.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-callout-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-card-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/card.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-card-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-checkbox-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/checkbox.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-checkbox-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-cta-button-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/cta-button.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-cta-button-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-cta-link-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/cta-link.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-cta-link-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-currency-field-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/currency-field.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-currency-field-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-data-table-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/data-table.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-data-table-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-date-field-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/date-field.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-date-field-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-disclosure-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/disclosure.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-disclosure-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-error-summary-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/error-summary.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-error-summary-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-grid-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/grid.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-grid-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-heading-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/heading.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-heading-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-icon-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/icon.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-icon-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-list-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/list.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-list-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-margin-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/margin.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-margin-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-modal-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/modal.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-modal-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-notification-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/notification.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-notification-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-radio-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/radio.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-radio-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-select-field-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/select-field.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-select-field-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-skeleton-loading-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/skeleton-loading.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-skeleton-loading-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-stack-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/stack.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-stack-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-subcomponents-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/subcomponents.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-subcomponents-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-summary-list-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/summary-list.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-summary-list-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-tabs-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/tabs.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-tabs-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-text-field-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/text-field.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-text-field-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-text-link-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/text-link.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-text-link-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-text-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/text.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-text-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-textarea-field-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/textarea-field.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-textarea-field-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-timeline-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/timeline.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-timeline-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-visually-hidden-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/components/visually-hidden.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-components-visually-hidden-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-borders-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/foundations/borders.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-borders-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-colour-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/foundations/colour.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-colour-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-elevation-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/foundations/elevation.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-elevation-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-interaction-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/foundations/interaction.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-interaction-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-layout-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/foundations/layout.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-layout-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-motion-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/foundations/motion.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-motion-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-spacing-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/foundations/spacing.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-spacing-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-typography-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/foundations/typography.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-foundations-typography-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-get-started-figma-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/get-started/figma.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-get-started-figma-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-get-started-setup-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/get-started/setup.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-get-started-setup-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-about-nebula-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/guides/about-nebula.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-about-nebula-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-accessibility-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/guides/accessibility.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-accessibility-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-component-criteria-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/guides/component-criteria.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-component-criteria-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-contribution-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/guides/contribution.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-contribution-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-documentation-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/guides/documentation.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-documentation-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-forms-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/guides/forms.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-forms-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-team-collaboration-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/guides/team-collaboration.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-team-collaboration-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-theme-mdx": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/docs/src/content/guides/theme.mdx" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-src-content-guides-theme-mdx" */),
  "component---src-templates-mdx-template-tsx-content-file-path-web-changelog-md": () => import("./../../../src/templates/MDXTemplate.tsx?__contentFilePath=/Users/jamesbaum/ovo/nebula/packages/web/CHANGELOG.md" /* webpackChunkName: "component---src-templates-mdx-template-tsx-content-file-path-web-changelog-md" */)
}

