var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Download = function (props) { return (React.createElement("svg", __assign({ viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.778 8a6.222 6.222 0 1112.444 0A6.222 6.222 0 011.778 8zM8 0a8 8 0 100 16A8 8 0 108 0zm0 3.71c.49 0 .889.398.889.889v4.656l1.265-1.265a.889.889 0 111.257 1.257l-2.78 2.78-.002.002a.886.886 0 01-1.26-.003l-2.78-2.78A.889.889 0 115.846 7.99L7.11 9.255V4.599c0-.49.398-.889.889-.889z", fill: "currentColor" }))); };
