var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var FormGroup = styled.div(function (_a) {
    var field = _a.theme.field;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    &[data-error='true'] {\n      padding-left: ", ";\n      position: relative;\n\n      &:before {\n        content: '';\n        display: block;\n        position: absolute;\n        height: 100%;\n        right: 100%;\n        top: 0;\n        width: ", ";\n        border-radius: ", ";\n        background-color: ", ";\n      }\n    }\n  "], ["\n    &[data-error='true'] {\n      padding-left: ", ";\n      position: relative;\n\n      &:before {\n        content: '';\n        display: block;\n        position: absolute;\n        height: 100%;\n        right: 100%;\n        top: 0;\n        width: ", ";\n        border-radius: ", ";\n        background-color: ", ";\n      }\n    }\n  "])), field.gap, field.errorIndent.borderWidth, field.errorIndent.borderRadius, field.error.color);
});
export { FormGroup };
var templateObject_1;
