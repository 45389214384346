var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Children } from 'react';
import { css, useTheme } from 'styled-components';
function mergeRefs(ref1, ref2) {
    return function (element) {
        assignRef(ref1, element);
        assignRef(ref2, element);
    };
}
function assignRef(ref, element) {
    if (typeof ref === 'function') {
        ref(element);
    }
    else if (ref) {
        // @ts-ignore
        ref.current = element;
    }
}
function getText(children) {
    if (!Boolean(children) && typeof children !== 'number') {
        return '';
    }
    if (typeof children === 'object' && children.props) {
        return Children.map(children.props.children, function (child) {
            return getText(child);
        }).join('');
    }
    return String(children);
}
function isExternalLink(url) {
    return !!url && /^https?:\/\//.test(url);
}
function callAll() {
    var fns = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        fns[_i] = arguments[_i];
    }
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return fns.forEach(function (fn) { return fn && fn.apply(void 0, args); });
    };
}
function getErrorMessage(id, errors) {
    return (errors.find(function (_a) {
        var errorId = _a.id;
        return id === errorId;
    }) || { message: null })
        .message;
}
function remToNumber(rem) {
    return parseFloat(rem);
}
function pxToNumber(px) {
    return parseFloat(px);
}
function remToPx(rem) {
    return remToNumber(rem) * 10 + 'px';
}
function filterChildrenByType(children, type) {
    return Children.toArray(children).filter(function (child) { return (child === null || child === void 0 ? void 0 : child.type) === type; });
}
export var responsiveStyle = function (_a, theme) {
    var _b;
    var property = _a.property, value = _a.value;
    if (typeof value === 'undefined') {
        return '';
    }
    var values = Array.isArray(value) ? value : [value];
    var ascMediaQueries = [
        theme.mediaQueries.smallAndUp,
        theme.mediaQueries.mediumAndUp,
        theme.mediaQueries.largeAndUp,
    ];
    var base = values[0], rest = values.slice(1);
    return css(__assign((_b = {}, _b[property] = base, _b), rest.reduce(function (acc, val, i) {
        var _a, _b;
        return val && ascMediaQueries[i] // Skip empty values and media queries.
            ? __assign(__assign({}, acc), (_a = {}, _a[ascMediaQueries[i]] = (_b = {},
                _b[property] = val,
                _b), _a)) : acc;
    }, {})));
};
export var useResponsiveStyle = function (property, value) {
    var theme = useTheme();
    return responsiveStyle({ property: property, value: value }, theme);
};
export { mergeRefs, getText, isExternalLink, callAll, getErrorMessage, remToNumber, pxToNumber, remToPx, filterChildrenByType, };
