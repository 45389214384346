import { Theme } from '@ovotech/nebula';
import styled, { css } from 'styled-components';

export const Inline = styled.div<{ spaceBetween: keyof Theme['space'] }>(
  ({ theme: { space }, spaceBetween }) => css`
    display: flex;

    & > * + * {
      margin-left: ${space[spaceBetween]};
    }
  `,
);
