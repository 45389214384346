import { Small } from '@ovotech/nebula';
import React from 'react';
import styled, { css, DefaultTheme, useTheme } from 'styled-components';

export const CheckIcon = () => (
  <svg
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66522 14.7438L0.961865 8.62521C0.619218 8.25761 0.619218 7.66161 0.961865 7.29398L2.20272 5.96275C2.54537 5.59512 3.10097 5.59512 3.44361 5.96275L7.28567 10.0845L15.5149 1.25616C15.8576 0.888571 16.4132 0.888571 16.7558 1.25616L17.9967 2.58739C18.3393 2.95498 18.3393 3.55099 17.9967 3.91862L7.90612 14.7438C7.56343 15.1114 7.00787 15.1114 6.66522 14.7438Z"
      fill="currentColor"
    />
  </svg>
);

const ColorPanelWrapper = styled.div<{
  color?: string;
  backgroundColor: string;
}>(
  ({
    theme: {
      radii,
      lineHeights,
      space,
      fontSizes,
      colors,
      fontWeights,
      fonts,
      borderWidths,
    },
    color,
    backgroundColor,
  }) => css`
    border-radius: ${radii.standard};
    flex-grow: 1;
    line-height: ${lineHeights.standard};
    padding: ${space[4]} ${space[6]};
    font-size: ${fontSizes[3]};
    color: ${color ? color : colors.primaryContrast};
    font-weight: ${fontWeights.bold};
    font-family: ${fonts.display};
    background-color: ${backgroundColor};
    border: ${borderWidths.standard} solid ${colors.borderMuted};
  `,
);

const ColorPanelInner = styled.div(
  ({ theme: { mediaQueries } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: space-evenly;
    flex-direction: column;

    ${mediaQueries.largeAndUp} {
      flex-direction: row;
    }
  `,
);

type ThemeColor = keyof DefaultTheme['colors'];

export const ColorPanel = ({
  title,
  color,
  aaColours,
  textColor,
}: {
  title: string;
  color: ThemeColor;
  aaColours?: ThemeColor[];
  textColor?: ThemeColor;
}) => {
  const { colors } = useTheme();
  return (
    <ColorPanelWrapper
      color={textColor && colors[textColor]}
      backgroundColor={colors[color]}
    >
      <ColorPanelInner>
        <ColorPanelTitles>
          <ColorPanelTitle>{title}</ColorPanelTitle>
          <ColorPanelSubtitle>{colors[color]}</ColorPanelSubtitle>
        </ColorPanelTitles>
        {aaColours && aaColours.length ? (
          <ColorSpotGroup>
            {aaColours.map(aaColor => (
              <OverlapColorSpot
                key={aaColor}
                color={colors[aaColor]}
                baseColor={colors[color]}
              />
            ))}
            <A11yText>AA</A11yText>
          </ColorSpotGroup>
        ) : null}
      </ColorPanelInner>
    </ColorPanelWrapper>
  );
};

const ColorPanelTitles = styled.div<{ color?: string }>(({ color }) => {
  return css`
    color: ${color ? color : 'inherit'};
    display: flex;
  `;
});

const ColorPanelTitle = styled.div`
  flex-grow: 1;
`;

const ColorPanelSubtitle = styled(Small)(
  ({ theme: { fontWeights, space } }) => css`
    font-weight: ${fontWeights.normal};
    padding-left: ${space[2]};
    padding-right: ${space[2]};
    align-self: center;
  `,
);

export const ColorSpot = styled.div<{ color: string; baseColor: string }>(
  ({ theme: { borderWidths }, color, baseColor }) => css`
    background-color: ${color};
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    border: ${borderWidths.standard} solid ${baseColor};
    box-sizing: content-box;
    flex-shrink: 0;
  `,
);

export const OverlapColorSpot = styled(ColorSpot)(
  ({ theme: { space } }) => css`
    margin-left: -${space[1]};
    margin-right: -${space[1]};
  `,
);

const ColorSpotGroup = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;

    & + & {
      margin-left: ${space[4]};
    }
  `,
);

const StyledA11yText = styled.div(
  ({ theme: { space } }) => css`
    margin-left: ${space[4]};
  `,
);
const A11yText = ({ children, ...rest }: any) => (
  <StyledA11yText {...rest}>
    <CheckIcon /> {children}
  </StyledA11yText>
);

export const ColorInfo = styled.div(
  ({ theme: { space, radii, colors } }) => css`
    background-color: ${colors.canvasMuted};
    padding: ${space[4]} ${space[6]};
    border-radius: ${radii.standard};
  `,
);
