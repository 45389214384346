import * as React from 'react';

export const ColourIcon = ({ color = '#0A1B30', ...rest }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M14 10C14 5.58172 17.5817 2 22 2V2C26.4183 2 30 5.58172 30 10V10C30 14.4183 26.4183 18 22 18V18C17.5817 18 14 14.4183 14 10V10Z"
        fill={color}
        fillOpacity={0.5}
      />
      <path
        d="M8 22C8 17.5817 11.5817 14 16 14V14C20.4183 14 24 17.5817 24 22V22C24 26.4183 20.4183 30 16 30V30C11.5817 30 8 26.4183 8 22V22Z"
        fill={color}
      />
      <path
        d="M2 10C2 5.58172 5.58172 2 10 2V2C14.4183 2 18 5.58172 18 10V10C18 14.4183 14.4183 18 10 18V18C5.58172 18 2 14.4183 2 10V10Z"
        fill={color}
        fillOpacity={0.75}
      />
    </svg>
  );
};
