var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Children, cloneElement, isValidElement, } from 'react';
import { ErrorText } from '../ErrorText';
import { FormGroup } from '../FormGroup';
import { HintText } from '../HintText';
import { LabelText } from '../LabelText';
import { Legend } from '../Legend';
export var CheckboxField = function (_a) {
    var label = _a.label, hint = _a.hint, error = _a.error, children = _a.children, _b = _a.optional, optional = _b === void 0 ? false : _b, ariaInvalid = _a["aria-invalid"], rest = __rest(_a, ["label", "hint", "error", "children", "optional", 'aria-invalid']);
    var hasError = !!error;
    var invalid = ariaInvalid != null ? ariaInvalid : hasError ? true : undefined;
    return (React.createElement(FormGroup, { "data-error": hasError || undefined },
        React.createElement("fieldset", __assign({ "aria-invalid": invalid }, rest),
            React.createElement(Legend, null,
                React.createElement(LabelText, null,
                    label,
                    optional ? ' (optional)' : null),
                hint ? React.createElement(HintText, null, hint) : null,
                error ? React.createElement(ErrorText, null, error) : null),
            Children.map(children, function (child) {
                return isValidElement(child)
                    ? cloneElement(child, {
                        'data-error': hasError ? true : child.props['data-error'],
                    })
                    : null;
            }))));
};
