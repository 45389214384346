import { Card, Heading2 } from '@ovotech/nebula';
import { Link } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledCard = styled(Card)(
  ({ theme: { focusRing, card, transitions } }) => css`
    text-decoration: none;
    overflow: hidden;
    display: block;
    height: 100%;

    ${Heading2} {
      text-decoration: underline;
    }

    &:focus {
      ${css(focusRing)}
    }

    &:hover {
      box-shadow: ${card.shadow};
      transition: ${transitions.standard};
    }
  `,
);

export const CardLink = ({ ...rest }: any) => (
  <StyledCard inline as={Link} {...rest} />
);
