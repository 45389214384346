var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled, { css } from 'styled-components';
var StyledTabPanel = styled.section(function (_a) {
    var _b = _a.theme, mediaQueries = _b.mediaQueries, card = _b.card, hidden = _a.hidden;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: ", ";\n    ", " {\n      padding: ", ";\n    }\n    display: ", ";\n    border-top-width: ", ";\n    border-style: solid;\n    border-color: ", ";\n  "], ["\n    padding: ", ";\n    ", " {\n      padding: ", ";\n    }\n    display: ", ";\n    border-top-width: ", ";\n    border-style: solid;\n    border-color: ", ";\n  "])), card.padding[0], mediaQueries.smallAndUp, card.padding[1], hidden ? 'none' : 'block', card.borderWidth, card.borderColor);
});
export var TabPanel = function (props) { return (React.createElement(StyledTabPanel, __assign({ role: "tabpanel" }, props))); };
var templateObject_1;
