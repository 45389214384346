import { Card } from '@ovotech/nebula';
import * as React from 'react';
import styled from 'styled-components';

const Figure = styled.figure(
  ({ theme: { text } }) => `
    display: inline-block;
    max-width: ${text.p.maxWidth};
  `,
);
const Image = styled.img`
  max-width: 100%;
`;
const FigCaption = styled.figcaption(
  ({ theme: { space, colors } }) => `
    text-align: center;
    margin-top: ${space[2]};
    font-style: italic;
    color: ${colors.body};
  `,
);
export const CaptionedImage = ({ src, caption = '', ...rest }: any) => (
  <Figure as={Card} inline {...rest}>
    <Image src={src} alt={caption} />
    {caption ? <FigCaption>{caption}</FigCaption> : null}
  </Figure>
);
