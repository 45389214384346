var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Dollar = function (props) { return (React.createElement("svg", __assign({ "aria-hidden": "true", viewBox: "0 0 16 16", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M8.345 7.022c-2.007-.524-2.652-1.066-2.652-1.91 0-.97.892-1.645 2.387-1.645 1.573 0 2.157.755 2.21 1.866h1.954c-.062-1.529-.99-2.933-2.838-3.386V0H6.753v1.92C5.038 2.293 3.66 3.413 3.66 5.129c0 2.053 1.689 3.075 4.155 3.671 2.21.533 2.653 1.316 2.653 2.142 0 .614-.434 1.591-2.387 1.591-1.822 0-2.538-.817-2.635-1.866H3.5c.106 1.946 1.556 3.04 3.253 3.404V16h2.653v-1.911c1.724-.329 3.094-1.333 3.094-3.156 0-2.524-2.148-3.386-4.155-3.91Z", fill: "currentColor" }))); };
