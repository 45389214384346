var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var Ul = styled.ul(function (_a) {
    var _b = _a.theme, list = _b.list, mediaQueries = _b.mediaQueries;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    list-style-type: disc;\n    margin-top: ", ";\n    margin-bottom: ", ";\n    margin-left: ", ";\n\n    ", " {\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "], ["\n    list-style-type: disc;\n    margin-top: ", ";\n    margin-bottom: ", ";\n    margin-left: ", ";\n\n    ", " {\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "])), list.marginTop[0], list.marginBottom[0], list.marginLeft, mediaQueries.smallAndUp, list.marginTop[1], list.marginBottom[1]);
});
export var Ol = styled.ol(function (_a) {
    var _b = _a.theme, list = _b.list, mediaQueries = _b.mediaQueries;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    list-style-type: decimal;\n    margin-top: ", ";\n    margin-bottom: ", ";\n    margin-left: ", ";\n\n    ", " {\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "], ["\n    list-style-type: decimal;\n    margin-top: ", ";\n    margin-bottom: ", ";\n    margin-left: ", ";\n\n    ", " {\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "])), list.marginTop[0], list.marginBottom[0], list.marginLeft, mediaQueries.smallAndUp, list.marginTop[1], list.marginBottom[1]);
});
export var Li = styled.li(function (_a) {
    var _b = _a.theme, text = _b.text, list = _b.list, mediaQueries = _b.mediaQueries;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n    margin: ", " auto;\n    font-size: ", ";\n\n    ", " {\n      font-size: ", ";\n    }\n\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "], ["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n    margin: ", " auto;\n    font-size: ", ";\n\n    ", " {\n      font-size: ", ";\n    }\n\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "])), text.fontFamily, text.lineHeight, text.fontWeight, text.color, list.listItem.marginVertical, list.listItem.fontSize[0], mediaQueries.smallAndUp, list.listItem.fontSize[1]);
});
export var Dl = styled.dl(function (_a) {
    var _b = _a.theme, list = _b.list, mediaQueries = _b.mediaQueries;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-top: ", ";\n    margin-bottom: ", ";\n\n    ", " {\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "], ["\n    margin-top: ", ";\n    margin-bottom: ", ";\n\n    ", " {\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "])), list.marginTop[0], list.marginBottom[0], mediaQueries.smallAndUp, list.marginTop[1], list.marginBottom[1]);
});
export var Dt = styled.dt(function (_a) {
    var _b = _a.theme, text = _b.text, list = _b.list, mediaQueries = _b.mediaQueries;
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n    margin-top: ", ";\n    margin-bottom: ", ";\n    font-size: ", ";\n\n    ", " {\n      font-size: ", ";\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "], ["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n    margin-top: ", ";\n    margin-bottom: ", ";\n    font-size: ", ";\n\n    ", " {\n      font-size: ", ";\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "])), text.fontFamily, text.lineHeight, list.descriptionTerm.fontWeight, list.descriptionTerm.color, list.descriptionTerm.marginTop[0], list.descriptionTerm.marginBottom[0], list.listItem.fontSize[0], mediaQueries.smallAndUp, list.listItem.fontSize[1], list.descriptionTerm.marginTop[1], list.descriptionTerm.marginBottom[1]);
});
export var Dd = styled.dd(function (_a) {
    var _b = _a.theme, text = _b.text, list = _b.list, mediaQueries = _b.mediaQueries;
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n    font-size: ", ";\n\n    ", " {\n      font-size: ", ";\n    }\n\n    dd + & {\n      margin-top: ", ";\n    }\n  "], ["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n    font-size: ", ";\n\n    ", " {\n      font-size: ", ";\n    }\n\n    dd + & {\n      margin-top: ", ";\n    }\n  "])), text.fontFamily, text.lineHeight, text.fontWeight, text.color, list.listItem.fontSize[0], mediaQueries.smallAndUp, list.listItem.fontSize[1], list.listItem.marginVertical);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
