var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, keyframes } from 'styled-components';
// The "vw" unit is used to keep animations consistent across skeletons of
// differing widths. The timing is adjusted with media queries to compensate.
var gleam = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  from {\n    background-position: -100vw 0;\n  }\n  to {\n    background-position: 200vw 0;\n  }\n"], ["\n  from {\n    background-position: -100vw 0;\n  }\n  to {\n    background-position: 200vw 0;\n  }\n"])));
export var Skeleton = styled.span(function (_a) {
    var mediaQueries = _a.theme.mediaQueries;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: relative;\n    overflow: hidden;\n    display: block;\n\n    &:after {\n      content: '';\n      position: absolute;\n      opacity: 0.6;\n      top: 0;\n      left: 0;\n      bottom: 0;\n      right: 0;\n      background: linear-gradient(\n        90deg,\n        rgba(255, 255, 255, 0) 0%,\n        rgba(255, 255, 255, 1) 50%,\n        rgba(255, 255, 255, 0) 100%\n      );\n      background-size: 100px;\n      background-repeat: no-repeat;\n      animation: ", " 2s infinite;\n\n      ", " {\n        animation-duration: 3s;\n      }\n\n      ", " {\n        animation-duration: 4s;\n      }\n\n      @media (prefers-reduced-motion) {\n        animation: none;\n      }\n    }\n  "], ["\n    position: relative;\n    overflow: hidden;\n    display: block;\n\n    &:after {\n      content: '';\n      position: absolute;\n      opacity: 0.6;\n      top: 0;\n      left: 0;\n      bottom: 0;\n      right: 0;\n      background: linear-gradient(\n        90deg,\n        rgba(255, 255, 255, 0) 0%,\n        rgba(255, 255, 255, 1) 50%,\n        rgba(255, 255, 255, 0) 100%\n      );\n      background-size: 100px;\n      background-repeat: no-repeat;\n      animation: ", " 2s infinite;\n\n      ", " {\n        animation-duration: 3s;\n      }\n\n      ", " {\n        animation-duration: 4s;\n      }\n\n      @media (prefers-reduced-motion) {\n        animation: none;\n      }\n    }\n  "])), gleam, mediaQueries.smallAndUp, mediaQueries.mediumAndUp);
});
var templateObject_1, templateObject_2;
