var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import { isExternalLink } from '../../utils';
import { CTAButtonBase } from '../CTAButton/CTAButton';
import { Icon } from '../Icon/Icon';
import { VisuallyHidden } from '../VisuallyHidden';
var CTALinkBase = CTAButtonBase.withComponent('a');
var CTALink = forwardRef(function (_a, ref) {
    var _b = _a.iconLeft, iconLeft = _b === void 0 ? 'off' : _b, iconRight = _a.iconRight, opensInNewWindow = _a.opensInNewWindow, children = _a.children, href = _a.href, rel = _a.rel, target = _a.target, rest = __rest(_a, ["iconLeft", "iconRight", "opensInNewWindow", "children", "href", "rel", "target"]);
    if (isExternalLink(href)) {
        rel = "".concat(rel || '', " noopener").trim();
    }
    var updatedTarget = opensInNewWindow ? '_blank' : target;
    if (!iconRight && opensInNewWindow) {
        iconRight = 'new-window';
    }
    else if (!iconRight) {
        iconRight = 'chevron-right';
    }
    return (React.createElement(CTALinkBase, __assign({}, rest, { ref: ref, rel: rel, target: updatedTarget, href: href }),
        iconLeft !== 'off' ? React.createElement(Icon, { name: iconLeft, size: 16 }) : null,
        React.createElement("span", null,
            children,
            opensInNewWindow && (React.createElement(VisuallyHidden, null, " (opens in new window)"))),
        iconRight !== 'off' ? React.createElement(Icon, { name: iconRight, size: 16 }) : null));
});
var PrimaryCTALink = forwardRef(function (props, ref) { return (React.createElement(CTALink, __assign({ variant: "primary", ref: ref }, props))); });
var SecondaryCTALink = forwardRef(function (props, ref) { return (React.createElement(CTALink, __assign({ variant: "secondary", ref: ref }, props))); });
var DestructiveSecondaryCTALink = forwardRef(function (props, ref) { return (React.createElement(CTALink, __assign({ variant: "destructive-secondary", ref: ref }, props))); });
export { CTALink, PrimaryCTALink, SecondaryCTALink, DestructiveSecondaryCTALink, };
