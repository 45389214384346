import {
  themeConfig as kaluzaThemeConfig,
  AdditionalConfig as KaluzaAdditionalConfig,
} from '@ovotech/kaluza-theme';
import { createTheme } from '@ovotech/nebula';
import {
  themeConfig as ovoThemeConfig,
  AdditionalConfig as OVOAdditionalConfig,
} from '@ovotech/ovoenergy-theme';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

function useThemes() {
  /**
   * WARNING
   * Avoid changing the out-the-box Nebula theme.
   */
  const nebulaLight = createTheme<{ isDefault: boolean }>({
    isDefault: true,
    text: {
      p: {
        maxWidth: '80ch',
      },
    },
  });

  const nebulaDark = createTheme({
    colors: {
      primary: '#21A5CE',
      primaryAlt: '#26B9E7',
      primaryContrast: '#1A1A1A',
      primaryMuted: '#182225',
      error: '#FF244B',
      errorAlt: '#FF3465',
      errorContrast: '#1A1A1A',
      errorMuted: '#30101A',
      info: '#11A0AF',
      infoAlt: '#16C0D2',
      infoContrast: '#1A1A1A',
      infoMuted: '#182222',
      warning: '#E75B19',
      warningAlt: '#FB6D2A',
      warningContrast: '#1A1A1A',
      warningMuted: '#2B1F19',
      success: '#2EA154',
      successAlt: '#3AC066',
      successContrast: '#1A1A1A',
      successMuted: '#1B241E',
      focus: '#0ABEC4',
      canvas: '#1D1D1D',
      body: '#FAFAFA',
      heading: '#FFFFFF',
      border: '#FAFAFA',
      canvasMuted: '#201919',
      borderMuted: '#4D4D4D',
    },
    text: {
      p: {
        maxWidth: '80ch',
      },
    },
  });

  const ovoTheme = createTheme<OVOAdditionalConfig | { isOVO: boolean }>(
    { ...ovoThemeConfig, isOVO: true },
    {
      text: {
        p: {
          maxWidth: '80ch',
        },
      },
    },
  );

  const kaluzaTheme = createTheme<KaluzaAdditionalConfig>(
    kaluzaThemeConfig.dark,
    {
      text: {
        p: {
          maxWidth: '80ch',
        },
      },
    },
  );

  return {
    'ovo-energy': ovoTheme,
    kaluza: kaluzaTheme,
    'nebula-light': nebulaLight,
    'nebula-dark': nebulaDark,
  };
}

const useTheme = () => {
  const theme = useContext(ThemeContext);
  return theme;
};

export { useTheme, useThemes };
