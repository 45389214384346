import styled, { css } from 'styled-components';

export const TitleBar = styled.div(
  ({ theme: { shadows, colors, space, mediaQueries, borderWidths } }) => css`
    box-shadow: ${shadows.standard};
    background-color: ${colors.canvas};
    padding: ${space[4]};
    display: flex;
    align-items: center;
    background: ${colors.canvas};
    justify-content: center;
    border-bottom: ${borderWidths.standard} solid ${colors.borderMuted};
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;

    ${mediaQueries.largeAndUp} {
      padding: ${space[8]};
    }
  `,
);
