import * as React from 'react';

export const FormsIcon = ({ color = '#0A1B30', ...rest }) => {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...rest}>
      <path d="M3 3h26v26H3V3z" stroke={color} strokeWidth={2} />
      <path stroke={color} strokeOpacity={0.75} d="M6 6.5h8" />
      <path
        stroke={color}
        strokeOpacity={0.5}
        strokeWidth={2}
        d="M6 9h20M6 15h20M6 21h20"
      />
      <path stroke={color} strokeOpacity={0.75} d="M6 12.5h8M6 18.5h8" />
      <path stroke={color} strokeOpacity={0.75} strokeWidth={2} d="M18 25h8" />
    </svg>
  );
};
