import styled, { css } from 'styled-components';

export const PageContent = styled.div(
  ({ theme: { space, mediaQueries } }) => css`
    padding-top: ${space[10]};
    padding-bottom: ${space[10]};

    ${mediaQueries.largeAndUp} {
      padding-top: ${space[12]};
      padding-bottom: ${space[12]};
    }
  `,
);
