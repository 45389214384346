import { SecondaryCTALink } from '@ovotech/nebula';
import styled from 'styled-components';

export const SkipLink = styled(SecondaryCTALink)`
  ${({ theme: { space } }) => `
    position: absolute;
    top: ${space[2]};
    left: -100%;
    z-index: 101;

    &:focus {
      left: ${space[2]};
    }
  `}
`;
