import { Theme } from '@ovotech/nebula';

import styled, { css } from 'styled-components';

export const Block = styled.div<{
  size?: keyof Theme['space'];
  width: keyof Theme['space'];
  height: keyof Theme['space'];
  backgroundColor: keyof Theme['colors'];
  borderRadius: keyof Theme['radii'];
  borderColor: keyof Theme['colors'];
  borderWidth: keyof Theme['borderWidths'];
}>(
  ({
    theme: { colors, space, radii, borderWidths },
    size = 4,
    width,
    height,
    backgroundColor = 'primary',
    borderRadius,
    borderColor,
    borderWidth,
  }) => css`
    height: ${space[size]};
    ${width ? `width: ${space[width]};` : null}
    ${height ? `height: ${space[height]};` : null}
    background-color: ${colors[backgroundColor]};
    ${borderRadius ? `border-radius: ${radii[borderRadius]}` : null};
    ${borderWidth ? `border-width: ${borderWidths[borderWidth]}` : null};
    ${borderColor ? `border-color: ${colors[borderColor]}` : null};
    border-style: solid;
  `,
);
