var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { Skeleton } from './Skeleton';
var StyledSkeletonCircleWrapper = styled(Skeleton)(function (_a) {
    var size = _a.size;
    return "width: ".concat(size, "; max-width: 100%;");
});
var StyledSkeletonCircle = styled(Skeleton)(function (_a) {
    var colors = _a.theme.colors;
    return "\n    background-color: ".concat(colors.borderMuted, ";\n    height: 0;\n    padding-bottom: 100%;\n    border-radius: 50%;\n  ");
});
export var SkeletonCircle = function (_a) {
    var _b = _a.size, size = _b === void 0 ? '12rem' : _b, rest = __rest(_a, ["size"]);
    return (React.createElement(StyledSkeletonCircleWrapper, __assign({ size: size }, rest),
        React.createElement(StyledSkeletonCircle, null)));
};
