import { useUID } from 'react-uid';
export var useUniqueId = function (prefix) {
    if (prefix === void 0) { prefix = 'nb'; }
    var id = useUID();
    return "".concat(prefix, "-").concat(id);
};
export var useIdSeed = function (prefix) {
    if (prefix === void 0) { prefix = 'nb'; }
    return function () { return useUniqueId(prefix); };
};
