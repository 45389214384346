import styled, { css } from 'styled-components';

export const Sidebar = styled.div(
  ({ theme: { colors, borderWidths, space, mediaQueries } }) => css`
    background-color: ${colors.canvas};
    position: fixed;
    left: 0;
    top: 8.8rem;
    padding: ${space[8]};
    bottom: 0;
    width: 25rem;
    z-index: 10;
    border-right: ${borderWidths.standard} solid ${colors.borderMuted};
    overflow-y: scroll;
    display: none;

    ${mediaQueries.largeAndUp} {
      display: block;
    }
  `,
);
