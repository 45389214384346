import { SecondaryCTAButton, TextLink, VisuallyHidden } from '@ovotech/nebula';
import packageJson from '@ovotech/nebula/package.json';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Container } from './Container';
import { GithubIcon, FigmaIcon } from './Icons';
import { SubMenu } from './SubMenu';

const Menu = styled(Container)(
  ({ theme: { colors, borderWidths } }) => css`
    background: ${colors.canvas};
    border-top: ${borderWidths.standard} solid ${colors.borderMuted};
    position: fixed;
    z-index: 100;
    top: 8rem;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  `,
);

const Scroll = styled.div(
  ({ theme: { space } }) => css`
    padding-top: ${space[4]};
    padding-bottom: ${space[4]};
    overflow: scroll;
    flex: 1;
  `,
);
const BottomBarSection = styled.div(
  ({ theme: { space, colors, borderWidths } }) => css`
    display: flex;
    justify-content: space-between;
    padding: ${space[4]} 0;
    border-top: ${borderWidths.standard} solid ${colors.borderMuted};
    > * > * + * {
      margin-left: ${space[6]};
    }
  `,
);

const version = packageJson.version;

export const MobileNav = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <SecondaryCTAButton onClick={() => setOpen(!open)} aria-expanded={open}>
        {open ? 'Close' : 'Menu'}
      </SecondaryCTAButton>
      {open ? (
        <Menu onClick={() => setOpen(false)}>
          <Scroll>
            <SubMenu showAbout />
          </Scroll>
          <BottomBarSection>
            <div>
              <TextLink
                href="https://forms.gle/bsBsmkszp85Nvi3MA"
                opensInNewWindow
              >
                Feedback
              </TextLink>
              <TextLink
                to="/changelogs"
                as={Link}
                activeClassName="active"
                partiallyActive={true}
              >
                v{version} <VisuallyHidden>Change Log</VisuallyHidden>
              </TextLink>
            </div>
            <div>
              <TextLink
                href="https://github.com/ovotech/designsystem_byus"
                target="_blank"
              >
                <GithubIcon />
                <VisuallyHidden>Visit Github Project</VisuallyHidden>
              </TextLink>
              <TextLink href="https://figma.com" target="_blank">
                <FigmaIcon />
                <VisuallyHidden>Visit Figma Project</VisuallyHidden>
              </TextLink>
            </div>
          </BottomBarSection>
        </Menu>
      ) : null}
    </div>
  );
};
