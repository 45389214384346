var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var Card = styled.div(function (_a) {
    var _b = _a.theme, card = _b.card, mediaQueries = _b.mediaQueries, _c = _a.inline, inline = _c === void 0 ? false : _c;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: ", ";\n    border-radius: ", ";\n    border: ", " solid ", ";\n    box-shadow: ", ";\n    padding: ", ";\n\n    ", " {\n      padding: ", ";\n    }\n  "], ["\n    background: ", ";\n    border-radius: ", ";\n    border: ", " solid ", ";\n    box-shadow: ", ";\n    padding: ", ";\n\n    ", " {\n      padding: ", ";\n    }\n  "])), card.background, card.borderRadius, card.borderWidth, card.borderColor, inline ? 'none' : card.shadow, card.padding[0], mediaQueries.smallAndUp, card.padding[1]);
});
export { Card };
var templateObject_1;
