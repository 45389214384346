var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Electricity = function (props) { return (React.createElement("svg", __assign({ viewBox: "0 0 16 16", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.246 9.44l1 6.56L14.5 4H10l1.5-4H5c-.382 1.015-1.93 5.196-2.834 7.64L1.5 9.44h4.746zm-2.161-1.8A8716.587 8716.587 0 016.249 1.8h2.654l-1.5 4h3.906l-3.033 5.016-.484-3.176H4.085z", fill: "currentColor" }))); };
