var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Plus = function (props) { return (React.createElement("svg", __assign({ viewBox: "0 0 16 16", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M10 1H6v5H1v4h5v5h4v-5h5V6h-5V1z", fill: "currentColor" }))); };
