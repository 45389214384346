import styled, { css } from 'styled-components';

export const InlineCode = styled.span(
  ({ theme: { radii, space, fonts, colors, borderWidths } }) => css`
    background-color: ${colors.canvasMuted};
    border-radius: ${radii.standard};
    font-family: ${fonts.monospace};
    padding: ${space[1]} ${space[2]};
    border: ${borderWidths.standard} solid ${colors.borderMuted};
  `,
);
