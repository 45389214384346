var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var ContentGrid = styled.div(function (_a) {
    var _b = _a.theme, grid = _b.grid, mediaQueries = _b.mediaQueries, size = _a.size;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      display: grid;\n      grid-gap: ", ";\n\n      ", " {\n        grid-gap: ", ";\n      }\n\n      @supports (width: min(", ", 100%)) {\n        grid-template-columns: repeat(\n          auto-fit,\n          minmax(min(", ", 100%), 1fr)\n        );\n      }\n    "], ["\n      display: grid;\n      grid-gap: ", ";\n\n      ", " {\n        grid-gap: ", ";\n      }\n\n      @supports (width: min(", ", 100%)) {\n        grid-template-columns: repeat(\n          auto-fit,\n          minmax(min(", ", 100%), 1fr)\n        );\n      }\n    "])), grid.gutter[0], mediaQueries.smallAndUp, grid.gutter[1], size, size);
});
export { ContentGrid };
var templateObject_1;
