import * as React from 'react';

export const ElevationIcon = ({ color = '#0A1B30', ...rest }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path d="M2 21L30 21V29L2 29L2 21Z" fill={color} />
      <path d="M9 12L30 12V20L9 20L9 12Z" fill={color} fillOpacity={0.75} />
      <path d="M16 3L30 3V11L16 11L16 3Z" fill={color} fillOpacity={0.5} />
    </svg>
  );
};
