var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { useResponsiveStyle } from '../../hooks';
var Margin = styled.div(function (_a) {
    var space = _a.theme.space, top = _a.top, bottom = _a.bottom, left = _a.left, right = _a.right, horizontal = _a.horizontal, vertical = _a.vertical, all = _a.all, inline = _a.inline;
    var formatter = function (spaceKey) { return space[spaceKey]; };
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n  "], ["\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n    ", "\n  "])), horizontal
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            ", "\n            ", "\n          "], ["\n            ", "\n            ", "\n          "])), useResponsiveStyle('margin-left', horizontal, formatter), useResponsiveStyle('margin-right', horizontal, formatter)) : '', vertical
        ? css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            ", "\n            ", "\n          "], ["\n            ", "\n            ", "\n          "])), useResponsiveStyle('margin-top', vertical, formatter), useResponsiveStyle('margin-bottom', vertical, formatter)) : '', top ? useResponsiveStyle('margin-top', top, formatter) : '', bottom ? useResponsiveStyle('margin-bottom', bottom, formatter) : '', left ? useResponsiveStyle('margin-left', left, formatter) : '', right ? useResponsiveStyle('margin-right', right, formatter) : '', all ? useResponsiveStyle('margin', all, formatter) : '', inline ? 'display: inline-block;' : '');
});
export { Margin };
var templateObject_1, templateObject_2, templateObject_3;
