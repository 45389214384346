var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { remToNumber } from '../../utils';
import { Icon } from '../Icon/Icon';
var StyledInput = styled.input(function (_a) {
    var _b = _a.theme, input = _b.input, mediaQueries = _b.mediaQueries, focusRing = _b.focusRing, iconLeft = _a.iconLeft, iconRight = _a.iconRight;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    height: ", ";\n    color: ", ";\n    border: ", " solid ", ";\n    background: ", ";\n    border-radius: ", ";\n    padding: ", ";\n    font-size: ", ";\n    width: 100%;\n\n    ", "\n    ", "\n\n    ", " {\n      font-size: ", ";\n    }\n\n    &:focus {\n      ", "\n    }\n\n    &[aria-invalid] {\n      border-width: ", ";\n      border-color: ", ";\n    }\n  "], ["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    height: ", ";\n    color: ", ";\n    border: ", " solid ", ";\n    background: ", ";\n    border-radius: ", ";\n    padding: ", ";\n    font-size: ", ";\n    width: 100%;\n\n    ", "\n    ", "\n\n    ", " {\n      font-size: ", ";\n    }\n\n    &:focus {\n      ", "\n    }\n\n    &[aria-invalid] {\n      border-width: ", ";\n      border-color: ", ";\n    }\n  "])), input.fontFamily, input.lineHeight, input.fontWeight, input.height, input.color, input.borderWidth, input.borderColor, input.background, input.borderRadius, input.padding, input.fontSize[0], iconLeft ? "padding-left: ".concat(input.height, ";") : '', iconRight ? "padding-right: ".concat(input.height, ";") : '', mediaQueries.smallAndUp, input.fontSize[1], focusRing, input.error.borderWidth, input.error.color);
});
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var StyledIcon = styled(Icon)(function (_a) {
    var input = _a.theme.input, side = _a.side;
    var distance = (remToNumber(input.height) - remToNumber(input.icon.size)) / 2;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      position: absolute;\n      width: ", ";\n      top: ", "rem;\n      ", ";\n      ", ";\n\n      path {\n        fill: ", ";\n      }\n\n      &[data-error='true'] {\n        path {\n          fill: ", ";\n        }\n      }\n    "], ["\n      position: absolute;\n      width: ", ";\n      top: ", "rem;\n      ", ";\n      ", ";\n\n      path {\n        fill: ", ";\n      }\n\n      &[data-error='true'] {\n        path {\n          fill: ", ";\n        }\n      }\n    "])), input.icon.size, distance, side === 'left' ? "left: ".concat(distance, "rem") : '', side === 'right' ? "right: ".concat(distance, "rem") : '', input.color, input.error.color);
});
var Input = forwardRef(function (_a, ref) {
    var iconLeft = _a.iconLeft, iconRight = _a.iconRight, rest = __rest(_a, ["iconLeft", "iconRight"]);
    var input = useContext(ThemeContext).input;
    return (React.createElement(Wrapper, null,
        iconLeft ? (React.createElement(StyledIcon, { name: iconLeft, size: remToNumber(input.icon.size) * 10, color: input.color, pointerEvents: "none", side: "left" })) : null,
        React.createElement(StyledInput, __assign({ iconLeft: !!iconLeft, iconRight: !!iconRight }, rest, { ref: ref })),
        iconRight ? (React.createElement(StyledIcon, { name: iconRight, size: remToNumber(input.icon.size) * 10, color: input.color, pointerEvents: "none", side: "right" })) : null));
});
export { Input, StyledInput };
var templateObject_1, templateObject_2, templateObject_3;
