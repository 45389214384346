import * as React from 'react';

export const TypographyIcon = ({ color = '#0A1B30', ...rest }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M18.1509 23H14.8491L13.5366 19.5854H7.52783L6.28711 23H3.06738L8.92236 7.96777H12.1318L18.1509 23ZM12.5625 17.0527L10.4912 11.4746L8.46094 17.0527H12.5625Z"
        fill={color}
      />
      <path
        d="M21.9038 15.4326L19.2891 14.9609C19.583 13.9082 20.0889 13.1289 20.8066 12.623C21.5244 12.1172 22.5908 11.8643 24.0059 11.8643C25.291 11.8643 26.248 12.0181 26.877 12.3257C27.5059 12.6265 27.9468 13.0127 28.1997 13.4844C28.4595 13.9492 28.5894 14.8071 28.5894 16.0581L28.5586 19.4214C28.5586 20.3784 28.603 21.0859 28.6919 21.5439C28.7876 21.9951 28.9619 22.4805 29.2148 23H26.3643C26.2891 22.8086 26.1968 22.5249 26.0874 22.1489C26.0396 21.978 26.0054 21.8652 25.9849 21.8105C25.4927 22.2891 24.9663 22.6479 24.4058 22.8872C23.8452 23.1265 23.2471 23.2461 22.6113 23.2461C21.4902 23.2461 20.605 22.9419 19.9556 22.3335C19.313 21.7251 18.9917 20.9561 18.9917 20.0264C18.9917 19.4111 19.1387 18.8643 19.4326 18.3857C19.7266 17.9004 20.1367 17.5312 20.6631 17.2783C21.1963 17.0186 21.9619 16.793 22.96 16.6016C24.3066 16.3486 25.2397 16.1128 25.7593 15.894V15.6069C25.7593 15.0532 25.6226 14.6602 25.3491 14.4277C25.0757 14.1885 24.5596 14.0688 23.8008 14.0688C23.2881 14.0688 22.8882 14.1714 22.6011 14.3765C22.314 14.5747 22.0815 14.9268 21.9038 15.4326ZM25.7593 17.7705C25.3901 17.8936 24.8057 18.0405 24.0059 18.2114C23.2061 18.3823 22.6831 18.5498 22.437 18.7139C22.061 18.9805 21.873 19.3188 21.873 19.729C21.873 20.1323 22.0234 20.481 22.3242 20.7749C22.625 21.0688 23.0078 21.2158 23.4727 21.2158C23.9922 21.2158 24.4878 21.0449 24.9595 20.7031C25.3081 20.4434 25.5371 20.1255 25.6465 19.7495C25.7217 19.5034 25.7593 19.0352 25.7593 18.3447V17.7705Z"
        fill={color}
        fillOpacity="0.75"
      />
    </svg>
  );
};
