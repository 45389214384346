import {
  DataTable,
  RequiredThemeKeys,
  Theme,
  createTheme,
  Disclosure,
} from '@ovotech/nebula';
import deepmerge from 'deepmerge';
import { flatten, unflatten } from 'flat';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { ColorFormat } from './ColorFormat';

const requiredThemeKeys: Array<keyof RequiredThemeKeys> = [
  'borderWidths',
  'radii',
  'breakpoints',
  'transitions',
  'shadows',
  'space',
  'fonts',
  'lineHeights',
  'fontSizes',
  'responsiveFontSizes',
  'fontWeights',
  'colors',
];

export const ThemeTable = ({
  keys = [],
  pick = [],
  matches,
  expanded = false,
}: {
  keys: Array<keyof Theme>;
  pick: Array<string>;
  matches?: string;
  expanded?: boolean;
}) => {
  const theme = useContext(ThemeContext);
  const baseTokens = requiredThemeKeys.reduce(
    (requiredTokens, key) => ({ ...requiredTokens, [key]: theme[key] }),
    {},
  );
  const tokenizedTheme = deepmerge(
    createTheme(
      // @ts-ignore
      unflatten(
        Object.entries(
          // @ts-ignore
          flatten(baseTokens),
        ).reduce((acc, [key]) => ({ ...acc, [key]: `[${key}]` }), {}),
      ),
    ),
    baseTokens,
    {
      arrayMerge: (_, dest) => dest,
    },
  );
  const tokenData = (partialTheme: Partial<Theme>) =>
    Object.entries(
      // @ts-ignore
      flatten(partialTheme, {
        safe: false,
      }),
    ).map(([key, value]) => {
      const tokenRegex = /(\[.*?])/g;
      const splitup: Array<string> = String(value).split(tokenRegex);
      value =
        typeof value === 'string' || typeof value === 'number'
          ? splitup.map(part =>
              part.match(tokenRegex)?.length ? (
                part.substr(1).slice(0, -1)
              ) : part.indexOf('#') === 0 ? (
                <ColorFormat>{part}</ColorFormat>
              ) : (
                part
              ),
            )
          : typeof value === 'function'
          ? Function
          : '';
      return [key, value];
    }) as string[][];

  const itemData = tokenData(
    keys.reduce(
      (partialTheme, key) => ({
        ...partialTheme,
        [key]: tokenizedTheme[key],
      }),
      {},
    ),
  )
    .filter(([key]) => !pick.length || pick.includes(key))
    .filter(([key]) => !matches || key.match(matches));

  return itemData.length ? (
    <Disclosure
      title="Theme tokens"
      expanded={expanded}
      style={{ display: 'block', marginTop: '16px' }}
    >
      <DataTable
        items={itemData}
        columnHeadings={['Token', 'Default Value']}
        columnWidths={['50%', '50%']}
      />
    </Disclosure>
  ) : null;
};
