import { Heading2, P, Badge, TextLink, Card } from '@ovotech/nebula';
import { Link } from 'gatsby';
import { Interval, DateTime } from 'luxon';
import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { truncate } from '../utils';
import { AccessibilityIcon } from './AccessibilityIcon';
import { BordersIcon } from './BordersIcon';
import { ColourIcon } from './ColourIcon';
import { ElevationIcon } from './ElevationIcon';
import { FormsIcon } from './FormsIcon';
import { GridIcon } from './GridIcon';
import { MotionIcon } from './MotionIcon';
import { SpaceIcon } from './SpaceIcon';
import { StackIcon } from './StackIcon';
import { TypographyIcon } from './TypographyIcon';

const iconMap = {
  forms: FormsIcon,
  borders: BordersIcon,
  space: SpaceIcon,
  motion: MotionIcon,
  typography: TypographyIcon,
  elevation: ElevationIcon,
  colour: ColourIcon,
  stack: StackIcon,
  grid: GridIcon,
  accessibility: AccessibilityIcon,
};

type PreviewIcon = typeof iconMap;

const Icon = ({ component, ...rest }: any) => {
  const { colors } = useContext(ThemeContext);
  const ComponentIcon = component;
  return (
    <ComponentIcon color={colors.primary} height={64} width={64} {...rest} />
  );
};

const BadgeWithMargin = styled(Badge)(
  ({ theme: { space } }) => css`
    margin-left: ${space[2]};
  `,
);

const StyledLink = styled(Link)(
  ({ theme: { colors } }) => css`
    color: ${colors.heading};
  `,
);

const Preview = styled.div(
  ({ theme: { card, colors, radii, mediaQueries } }) => css`
    margin: -${card.padding[0]};
    margin-bottom: 0;
    padding: ${card.padding[0]};

    ${mediaQueries.smallAndUp} {
      margin: -${card.padding[1]};
      margin-bottom: 0;
      padding: ${card.padding[1]};
    }

    display: flex;
    justify-content: center;
    background: ${colors.canvasMuted};
    border-top-left-radius: ${radii.standard};
    border-top-right-radius: ${radii.standard};
  `,
);

type PreviewCardProps = {
  icon?: keyof PreviewIcon;
  link: string;
  title: string;
  summary?: string;
  lastUpdated?: string;
};

export const PreviewCard = ({
  icon,
  link,
  title,
  summary,
  lastUpdated,
}: PreviewCardProps) => (
  <Card inline style={{ height: '100%' }}>
    {icon && Object.keys(iconMap).includes(icon) ? (
      <Preview>
        <Icon component={iconMap[icon]} role="presentation" height={48} />
      </Preview>
    ) : null}
    <Heading2>
      <TextLink
        inline
        as={StyledLink}
        to={link}
        style={{ fontWeight: 'unset' }}
      >
        {title}
      </TextLink>
      {lastUpdated &&
      Interval.fromDateTimes(
        DateTime.fromISO(lastUpdated),
        DateTime.local(),
      ).count('weeks') < 4 ? (
        <BadgeWithMargin>New</BadgeWithMargin>
      ) : null}
    </Heading2>
    {summary && <P>{truncate(summary, 65)}</P>}
  </Card>
);
