import styled from 'styled-components';

export const Text = styled.div(
  ({
    theme: { fonts, fontWeights, fontSizes },
    fontWeight = 'normal',
    fontSize = 2,
    fontFamily = 'body',
  }: any) => `
    font-family: ${fonts[fontFamily]};
    font-weight: ${fontWeights[fontWeight]};
    font-size: ${fontSizes[fontSize]};
`,
);
