import deepMerge from 'deepmerge';
export var defaultTokens = {
    borderWidths: {
        standard: '0.1rem',
        heavy: '0.2rem',
        heavier: '0.4rem',
    },
    radii: {
        standard: '0.4rem',
    },
    breakpoints: { small: '480px', medium: '768px', large: '1208px' },
    transitions: {
        standard: '0.2s ease',
    },
    shadows: {
        standard: '0 0 6px 0 rgba(0, 0, 0, 0.1);',
    },
    space: {
        1: '0.4rem',
        2: '0.8rem',
        3: '1.2rem',
        4: '1.6rem',
        5: '2rem',
        6: '2.4rem',
        7: '2.8rem',
        8: '3.2rem',
        9: '3.6rem',
        10: '4.0rem',
        11: '4.4rem',
        12: '4.8rem',
    },
    fonts: {
        display: 'Arial',
        body: 'Arial',
        monospace: 'Courier',
    },
    lineHeights: {
        standard: 1.5,
    },
    fontSizes: [
        '1.2rem',
        '1.4rem',
        '1.6rem',
        '2.0rem',
        '2.4rem',
        '2.8rem',
        '3.2rem',
    ],
    fontWeights: {
        normal: 400,
        bold: 700,
        semiBold: 600,
    },
    colors: {
        primary: '#0C7494',
        primaryAlt: '#096885',
        primaryContrast: '#FFFFFF',
        primaryMuted: '#F3F7F9',
        error: '#C7153E',
        errorAlt: '#AE0A30',
        errorContrast: '#FFFFFF',
        errorMuted: '#FCF3F5',
        info: '#147D88',
        infoAlt: '#05696C',
        infoContrast: '#FFFFFF',
        infoMuted: '#F2F7F8',
        warning: '#C64A18',
        warningAlt: '#B74E08',
        warningContrast: '#FFFFFF',
        warningMuted: '#FDF7F3',
        success: '#258244',
        successAlt: '#1E6937',
        successContrast: '#FFFFFF',
        successMuted: '#F4F9F6',
        focus: '#2A71BE',
        canvas: '#FFFFFF',
        body: '#555C6B',
        heading: '#2F3749',
        border: '#2F3749',
        canvasMuted: '#F9F9F9',
        borderMuted: '#D9D9D9',
    },
    responsiveFontSizes: {
        small: ['1.2rem', '1.4rem'],
        body: ['1.4rem', '1.6rem'],
        level1: ['2.8rem', '3.2rem'],
        level2: ['2.4rem', '2.8rem'],
        level3: ['2.0rem', '2.4rem'],
        level4: ['1.6rem', '2.0rem'],
    },
};
export function createTheme(customTheme, additional) {
    var mergeOptions = { arrayMerge: function (_, source) { return source; } };
    var customThemeWithAdditional = deepMerge(
    // @ts-ignore
    customTheme, additional || {}, mergeOptions);
    var theme = deepMerge(defaultTokens, customThemeWithAdditional, mergeOptions);
    var fontSizes = theme.fontSizes, breakpoints = theme.breakpoints, colors = theme.colors, transitions = theme.transitions, shadows = theme.shadows, fontWeights = theme.fontWeights, fonts = theme.fonts, lineHeights = theme.lineHeights, space = theme.space, radii = theme.radii, borderWidths = theme.borderWidths, responsiveFontSizes = theme.responsiveFontSizes;
    return deepMerge({
        field: {
            fontFamily: fonts.body,
            fontSize: responsiveFontSizes.body,
            lineHeight: lineHeights.standard,
            dateWidth: '26.5rem',
            gap: space[2],
            error: {
                color: colors.error,
                fontWeight: fontWeights.bold,
            },
            hint: {
                fontWeight: fontWeights.normal,
                color: colors.body,
            },
            label: {
                color: colors.heading,
                fontWeight: fontWeights.bold,
            },
            subLabel: {
                color: colors.heading,
                fontWeight: fontWeights.semiBold,
            },
            legend: {
                marginBottom: space[1],
            },
            errorIndent: {
                borderWidth: borderWidths.heavier,
                borderRadius: radii.standard,
            },
        },
        mediaQueries: {
            smallAndUp: "@media screen and (min-width: ".concat(breakpoints.small, ")"),
            mediumAndUp: "@media screen and (min-width: ".concat(breakpoints.medium, ")"),
            largeAndUp: "@media screen and (min-width: ".concat(breakpoints.large, ")"),
        },
        input: {
            fontFamily: fonts.body,
            fontSize: responsiveFontSizes.body,
            color: colors.body,
            lineHeight: lineHeights.standard,
            fontWeight: fontWeights.normal,
            height: space[12],
            borderColor: colors.border,
            borderWidth: borderWidths.standard,
            background: colors.canvas,
            padding: space[2],
            borderRadius: radii.standard,
            error: {
                borderWidth: borderWidths.heavy,
                color: colors.error,
            },
            icon: {
                size: space[4],
            },
        },
        card: {
            background: colors.canvas,
            borderWidth: borderWidths.standard,
            borderColor: colors.borderMuted,
            borderRadius: radii.standard,
            shadow: shadows.standard,
            padding: [space[4], space[8]],
        },
        badge: {
            fontWeight: fontWeights.bold,
            lineHeight: lineHeights.standard,
            paddingVertical: space[1],
            paddingHorizontal: space[2],
            borderRadius: radii.standard,
            borderWidth: borderWidths.standard,
            fontSize: responsiveFontSizes.small,
            variants: {
                default: {
                    background: colors.primaryMuted,
                    foreground: colors.primary,
                    borderColor: colors.primary,
                },
                error: {
                    background: colors.errorMuted,
                    foreground: colors.error,
                    borderColor: colors.error,
                },
                success: {
                    background: colors.successMuted,
                    foreground: colors.success,
                    borderColor: colors.success,
                },
                info: {
                    background: colors.infoMuted,
                    foreground: colors.info,
                    borderColor: colors.info,
                },
                warning: {
                    background: colors.warningMuted,
                    foreground: colors.warning,
                    borderColor: colors.warning,
                },
            },
        },
        cta: {
            fontSize: responsiveFontSizes.body,
            fontWeight: fontWeights.bold,
            font: fonts.display,
            lineHeight: lineHeights.standard,
            paddingVertical: space[2],
            paddingHorizontal: space[4],
            borderRadius: radii.standard,
            transition: transitions.standard,
            borderWidth: borderWidths.standard,
            iconGap: space[1],
            minHeight: space[12],
            variants: {
                primary: {
                    background: colors.primary,
                    foreground: colors.primaryContrast,
                    backgroundHover: colors.primaryAlt,
                    foregroundHover: colors.primaryContrast,
                    border: colors.primary,
                    borderHover: colors.primaryAlt,
                },
                secondary: {
                    background: colors.primaryContrast,
                    foreground: colors.primary,
                    backgroundHover: colors.primaryMuted,
                    foregroundHover: colors.primaryAlt,
                    border: colors.primary,
                    borderHover: colors.primaryAlt,
                },
                destructive: {
                    background: colors.error,
                    foreground: colors.errorContrast,
                    backgroundHover: colors.errorAlt,
                    foregroundHover: colors.errorContrast,
                    border: colors.error,
                    borderHover: colors.errorAlt,
                },
                'destructive-secondary': {
                    background: colors.errorContrast,
                    foreground: colors.error,
                    backgroundHover: colors.errorMuted,
                    foregroundHover: colors.errorAlt,
                    border: colors.error,
                    borderHover: colors.errorAlt,
                },
            },
        },
        checkbox: {
            touchSize: space[12],
            size: space[8],
            checkSize: space[6],
            gap: space[2],
            paddingTop: space[3],
            borderWidth: borderWidths.heavy,
            borderColor: colors.border,
            borderRadius: radii.standard,
            background: colors.canvas,
            checkColor: colors.primary,
            checkedBackground: colors.canvas,
            checkedBorderColor: colors.border,
            errorColor: colors.error,
            marginTop: '0',
        },
        errorNotification: {
            summaryLink: {
                color: colors.error,
                hover: colors.errorAlt,
            },
        },
        notification: {
            borderRadius: radii.standard,
            borderWidth: borderWidths.heavy,
            padding: [space[3], space[6]],
            dismiss: {
                touchSize: space[12],
                size: space[4],
                icon: 'cross',
                color: colors.heading,
            },
            variants: {
                error: {
                    borderColor: colors.error,
                    background: colors.errorMuted,
                },
                success: {
                    borderColor: colors.success,
                    background: colors.successMuted,
                },
                info: {
                    borderColor: colors.info,
                    background: colors.infoMuted,
                },
                warning: {
                    borderColor: colors.warning,
                    background: colors.warningMuted,
                },
            },
        },
        modal: {
            dismiss: {
                touchSize: space[12],
                size: space[4],
                icon: 'cross',
                color: colors.primary,
                colorHover: colors.primaryAlt,
            },
        },
        disclosure: {
            iconGap: [space[1], space[2]],
            panelGap: [space[2], space[3]],
            lineIndent: {
                borderRadius: radii.standard,
                width: borderWidths.heavier,
                backgroundColor: colors.borderMuted,
            },
        },
        text: {
            fontFamily: fonts.body,
            lineHeight: lineHeights.standard,
            fontWeight: fontWeights.normal,
            color: colors.body,
            p: {
                fontSize: responsiveFontSizes.body,
                marginTop: [space[2], space[3]],
                marginBottom: [space[2], space[3]],
                maxWidth: 'none',
            },
            small: {
                fontSize: responsiveFontSizes.small,
            },
            strong: {
                fontWeight: fontWeights.bold,
            },
        },
        heading: {
            fontFamily: fonts.display,
            lineHeight: lineHeights.standard,
            fontWeight: fontWeights.bold,
            color: colors.heading,
            heading1: {
                fontSize: responsiveFontSizes.level1,
                marginTop: [space[8], space[12]],
                marginBottom: [space[2], space[3]],
            },
            heading2: {
                fontSize: responsiveFontSizes.level2,
                marginTop: [space[6], space[8]],
                marginBottom: [space[2], space[3]],
            },
            heading3: {
                fontSize: responsiveFontSizes.level3,
                marginTop: [space[4], space[6]],
                marginBottom: [space[2], space[3]],
            },
            heading4: {
                fontSize: responsiveFontSizes.level4,
                marginTop: [space[3], space[4]],
                marginBottom: [space[2], space[3]],
            },
        },
        list: {
            marginTop: [space[2], space[3]],
            marginBottom: [space[2], space[3]],
            marginLeft: space[6],
            listItem: {
                fontSize: responsiveFontSizes.body,
                marginVertical: space[2],
            },
            descriptionTerm: {
                color: colors.heading,
                fontWeight: fontWeights.bold,
                marginTop: [space[3], space[4]],
                marginBottom: [space[1], space[1]],
            },
        },
        textLink: {
            color: colors.primary,
            hover: colors.primaryAlt,
            fontWeight: 'inherit',
        },
        radii: radii,
        radio: {
            touchSize: space[12],
            size: space[8],
            dotSize: space[5],
            gap: space[2],
            paddingTop: space[3],
            borderWidth: borderWidths.heavy,
            borderColor: colors.border,
            background: colors.canvas,
            dotColor: colors.primary,
            errorColor: colors.error,
            marginTop: '0',
        },
        fontWeights: fontWeights,
        fontSizes: fontSizes,
        fonts: fonts,
        focusRing: {
            outline: 'none',
            transition: 'box-shadow ' + transitions.standard,
            boxShadow: "0 0 0 0.2rem ".concat(colors.canvas, ", 0 0 0 0.4rem ").concat(colors.focus),
        },
        responsiveFontSizes: responsiveFontSizes,
        colors: colors,
        borderWidths: borderWidths,
        breakpoints: breakpoints,
        lineHeights: lineHeights,
        space: space,
        transitions: transitions,
        shadows: shadows,
        grid: {
            maxWidth: breakpoints.large,
            gutter: [space[4], space[8]],
        },
        dataTable: {
            borderWidth: borderWidths.standard,
            borderRadius: radii.standard,
            borderColor: colors.borderMuted,
            tableHeader: {
                color: colors.heading,
                backgroundColor: colors.canvas,
                borderWidth: borderWidths.standard,
                borderColor: colors.border,
                fontWeight: fontWeights.bold,
            },
            tableCaption: {
                color: colors.heading,
                fontWeight: fontWeights.bold,
                paddingVertical: space[3],
                paddingHorizontal: space[3],
            },
            tableCell: {
                color: colors.body,
                borderWidth: borderWidths.standard,
                borderColor: colors.borderMuted,
                paddingVertical: space[3],
                paddingHorizontal: space[3],
            },
            tableRow: {
                backgroundColor: colors.canvas,
                stripeColor: colors.canvasMuted,
            },
        },
        accordion: {
            header: {
                color: colors.primary,
                paddingVertical: [space[3], space[5]],
                iconSize: space[4],
                iconGap: space[3],
            },
        },
        tabs: {
            tab: {
                borderTopWidth: borderWidths.heavier,
                fontSize: responsiveFontSizes.body,
                fontWeight: fontWeights.bold,
                fontFamily: fonts.display,
                lineHeight: lineHeights.standard,
                paddingHorizontal: [space[4], space[4]],
                paddingVertical: [space[3], space[3]],
                active: {
                    borderTopColor: colors.primary,
                    foregroundColor: colors.heading,
                    backgroundColor: colors.canvas,
                },
                inactive: {
                    foregroundColor: colors.primary,
                    backgroundColor: colors.canvasMuted,
                },
                hover: {
                    foregroundColor: colors.primaryAlt,
                },
            },
        },
        callout: {
            paddingVertical: [space[3], space[4]],
            paddingHorizontal: [space[2], space[3]],
            borderRadius: radii.standard,
            borderWidth: borderWidths.standard,
            lineIndentWidth: borderWidths.heavier,
            iconGap: space[2],
            iconSize: [space[4], space[5]],
            variants: {
                error: {
                    background: colors.errorMuted,
                    foreground: colors.error,
                },
                success: {
                    background: colors.successMuted,
                    foreground: colors.success,
                },
                info: {
                    background: colors.infoMuted,
                    foreground: colors.info,
                },
                warning: {
                    background: colors.warningMuted,
                    foreground: colors.warning,
                },
            },
        },
        timeline: {
            gapHorizontal: [space[2], space[3]],
            gapVertical: [space[3], space[4]],
            separator: {
                width: borderWidths.heavy,
                color: colors.borderMuted,
            },
            node: {
                color: colors.border,
                size: [space[5], space[6]],
            },
        },
    }, theme, mergeOptions);
}
