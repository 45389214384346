import { TextLink } from '@ovotech/nebula';
import styled, { css } from 'styled-components';

export const MenuLink = styled(TextLink)(
  ({
    theme: {
      colors,
      fontWeights,
      focusRing,
      fonts,
      borderWidths,
      radii,
      space,
    },
    children,
  }) => css`
    text-decoration: none;
    font-family: ${fonts.body};
    font-weight: ${fontWeights.normal};
    color: ${colors.heading};
    text-align: center;

    &:after {
        content: '${children}';
        height: 0;
        display: block;
        user-select: none;
        pointer-events: none;
        font-weight: ${fontWeights.bold};
        visibility: hidden;

        @media speech {
          display: none;
        }
      }

    &.active {
      font-weight: ${fontWeights.bold};
      position: relative;

      &:before {
        content: ' ';
        display: block;
        height: ${borderWidths.heavy};
        background: ${colors.primary};
        border-radius: ${radii.standard};
        position: absolute;
        left: 0;
        right: 0;
        bottom: -${space[1]};
        z-index: -1;
      }
    }


    &:focus {
      ${css(focusRing)}
    }
  `,
);
