import { Row, Col } from '@ovotech/nebula';
import React from 'react';
import { PreviewCard } from './PreviewCard';

export const CardMenu = ({ nodes, section }: any) => (
  <nav aria-label={section}>
    <Row as="ul">
      {nodes.map((node: any, i: number) => (
        <Col as="li" small={12} medium={6} large={4} key={i}>
          <PreviewCard
            title={node.frontmatter.title}
            summary={node.frontmatter.summary}
            link={`/${section}/${node.frontmatter.slug}`}
            lastUpdated={node.frontmatter.released}
            icon={node.frontmatter.icon}
          />
        </Col>
      ))}
    </Row>
  </nav>
);
