var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var Badge = styled.span(function (_a) {
    var _b = _a.theme, badge = _b.badge, mediaQueries = _b.mediaQueries, _c = _a.variant, variant = _c === void 0 ? 'default' : _c;
    // @ts-ignore to support additional palettes.
    var currentVariant = badge.variants[variant];
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-weight: ", ";\n    line-height: ", ";\n    padding: ", " ", ";\n    border-radius: ", ";\n    color: ", ";\n    background-color: ", ";\n    border: ", " solid ", ";\n    vertical-align: middle;\n    white-space: nowrap;\n\n    font-size: ", ";\n    ", " {\n      font-size: ", ";\n    }\n  "], ["\n    font-weight: ", ";\n    line-height: ", ";\n    padding: ", " ", ";\n    border-radius: ", ";\n    color: ", ";\n    background-color: ", ";\n    border: ", " solid ", ";\n    vertical-align: middle;\n    white-space: nowrap;\n\n    font-size: ", ";\n    ", " {\n      font-size: ", ";\n    }\n  "])), badge.fontWeight, badge.lineHeight, badge.paddingVertical, badge.paddingHorizontal, badge.borderRadius, currentVariant.foreground, currentVariant.background, badge.borderWidth, currentVariant.borderColor, badge.fontSize[0], mediaQueries.smallAndUp, badge.fontSize[1]);
});
var templateObject_1;
