var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
function useResponsiveStyle(property, value, formatter) {
    var _a;
    var mediaQueries = useContext(ThemeContext).mediaQueries;
    var values;
    if (Array.isArray(value)) {
        values = value;
    }
    else {
        values = [value];
    }
    var ascMediaQueries = [
        mediaQueries.smallAndUp,
        mediaQueries.mediumAndUp,
        mediaQueries.largeAndUp,
    ];
    var base = values[0], rest = values.slice(1);
    return __assign((_a = {}, _a[property] = formatter ? formatter(base) : base, _a), rest.reduce(function (acc, val, i) {
        var _a, _b;
        return (__assign(__assign({}, acc), (ascMediaQueries[i]
            ? (_a = {},
                _a[ascMediaQueries[i]] = (_b = {},
                    _b[property] = formatter ? formatter(val) : val,
                    _b),
                _a) : {})));
    }, {}));
}
export { useResponsiveStyle };
