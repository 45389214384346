var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { remToNumber, useResponsiveStyle } from '../../utils';
import { HintText } from '../HintText';
import { SubLabelText } from '../SubLabelText';
var StyledWrapper = styled.div(function (_a) {
    var radio = _a.theme.radio;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    position: relative;\n\n    & + & {\n      ", "\n    }\n  "], ["\n    display: flex;\n    position: relative;\n\n    & + & {\n      ", "\n    }\n  "])), useResponsiveStyle('margin-top', radio.marginTop));
});
var RadioInput = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  opacity: 0;\n  width: 0;\n  height: 0;\n  position: absolute;\n  bottom: 100%;\n  right: 100%;\n"], ["\n  opacity: 0;\n  width: 0;\n  height: 0;\n  position: absolute;\n  bottom: 100%;\n  right: 100%;\n"])));
var RadioLabel = styled.label(function (_a) {
    var _b = _a.theme, radio = _b.radio, focusRing = _b.focusRing;
    var touchSize = remToNumber(radio.touchSize);
    var radioSize = remToNumber(radio.size);
    var dotSize = remToNumber(radio.dotSize);
    var gap = remToNumber(radio.gap);
    var radioDistance = (touchSize - radioSize) / 2;
    var dotDistance = (radioSize - dotSize) / 2;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding-left: ", "rem;\n    min-height: ", ";\n    position: relative;\n    ", "\n\n    &:before {\n      content: '';\n      position: absolute;\n      top: ", "rem;\n      left: 0;\n      width: ", ";\n      height: ", ";\n      border: ", " solid ", ";\n      background: ", ";\n      border-radius: 50%;\n    }\n\n    &:after {\n      content: '';\n      position: absolute;\n      top: ", "rem;\n      left: ", "rem;\n      width: ", ";\n      height: ", ";\n      background: ", ";\n      border-radius: 50%;\n      opacity: 0;\n    }\n\n    input:checked + &:after {\n      opacity: 1;\n    }\n\n    input:focus + &:before {\n      ", "\n    }\n\n    input[data-error] + &:before,\n    input[aria-invalid] + &:before {\n      border-color: ", ";\n    }\n  "], ["\n    padding-left: ", "rem;\n    min-height: ", ";\n    position: relative;\n    ", "\n\n    &:before {\n      content: '';\n      position: absolute;\n      top: ", "rem;\n      left: 0;\n      width: ", ";\n      height: ", ";\n      border: ", " solid ", ";\n      background: ", ";\n      border-radius: 50%;\n    }\n\n    &:after {\n      content: '';\n      position: absolute;\n      top: ", "rem;\n      left: ", "rem;\n      width: ", ";\n      height: ", ";\n      background: ", ";\n      border-radius: 50%;\n      opacity: 0;\n    }\n\n    input:checked + &:after {\n      opacity: 1;\n    }\n\n    input:focus + &:before {\n      ", "\n    }\n\n    input[data-error] + &:before,\n    input[aria-invalid] + &:before {\n      border-color: ", ";\n    }\n  "])), radioSize + gap, radio.touchSize, useResponsiveStyle('padding-top', radio.paddingTop), radioDistance, radio.size, radio.size, radio.borderWidth, radio.borderColor, radio.background, radioDistance + dotDistance, dotDistance, radio.dotSize, radio.dotSize, radio.dotColor, focusRing, radio.errorColor);
});
export var Radio = forwardRef(function (_a, ref) {
    var id = _a.id, label = _a.label, hint = _a.hint, rest = __rest(_a, ["id", "label", "hint"]);
    return (React.createElement(StyledWrapper, null,
        React.createElement(RadioInput, __assign({}, rest, { ref: ref, id: id, type: "radio" })),
        React.createElement(RadioLabel, { htmlFor: id },
            React.createElement(SubLabelText, null, label),
            hint ? React.createElement(HintText, null, hint) : null)));
});
var templateObject_1, templateObject_2, templateObject_3;
