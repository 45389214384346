var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var NewWindow = function (props) { return (React.createElement("svg", __assign({ viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { fill: "currentColor", d: "M10.3 0v1.8h2.5L8 6.1l1.3 1.3L14.1 3v2.5H16V0h-5.7z" }),
    React.createElement("path", { fill: "currentColor", d: "M13 9v5H2V3h5V1H0v15h15V9h-2z" }))); };
