var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { remToNumber } from '../../utils';
var Row = styled.div(function (_a) {
    var _b = _a.theme, grid = _b.grid, mediaQueries = _b.mediaQueries, isNested = _a.isNested;
    var halfGutter = grid.gutter.map(function (g) { return remToNumber(g) / 2 + 'rem'; });
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    box-sizing: border-box;\n    flex: 0 1 auto;\n    flex-direction: row;\n    flex-wrap: wrap;\n    padding-left: ", ";\n    padding-right: ", ";\n    margin: 0 auto;\n    width: ", ";\n    max-width: 100%;\n    margin-bottom: -", ";\n\n    & + & {\n      margin-top: ", ";\n    }\n\n    ", "\n\n    ", " {\n      padding-left: ", ";\n      padding-right: ", ";\n      margin-bottom: -", ";\n\n      & + & {\n        margin-top: ", ";\n      }\n\n      ", "\n    }\n  "], ["\n    display: flex;\n    box-sizing: border-box;\n    flex: 0 1 auto;\n    flex-direction: row;\n    flex-wrap: wrap;\n    padding-left: ", ";\n    padding-right: ", ";\n    margin: 0 auto;\n    width: ", ";\n    max-width: 100%;\n    margin-bottom: -", ";\n\n    & + & {\n      margin-top: ", ";\n    }\n\n    ", "\n\n    ", " {\n      padding-left: ", ";\n      padding-right: ", ";\n      margin-bottom: -", ";\n\n      & + & {\n        margin-top: ", ";\n      }\n\n      ", "\n    }\n  "])), halfGutter[0], halfGutter[0], grid.maxWidth, grid.gutter[0], grid.gutter[0], isNested
        ? "\n      margin-left: -".concat(grid.gutter[0], ";\n      margin-right: -").concat(grid.gutter[0], ";\n      width: auto;\n      max-width: none;\n    ")
        : null, mediaQueries.smallAndUp, halfGutter[1], halfGutter[1], grid.gutter[1], grid.gutter[1], isNested
        ? "\n        margin-left: -".concat(grid.gutter[1], ";\n        margin-right: -").concat(grid.gutter[1], ";\n      ")
        : null);
});
export { Row };
var templateObject_1;
