var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { createRef, useEffect, useState, forwardRef, } from 'react';
import styled, { css } from 'styled-components';
import { useUniqueId } from '../../hooks/use-unique-id';
export var DataTable = forwardRef(function (_a, ref) {
    var items = _a.items, columnHeadings = _a.columnHeadings, caption = _a.caption, rowHeadings = _a.rowHeadings, columnWidths = _a.columnWidths, _b = _a.striped, striped = _b === void 0 ? true : _b, children = _a.children, rest = __rest(_a, ["items", "columnHeadings", "caption", "rowHeadings", "columnWidths", "striped", "children"]);
    // add tabindex & make table focusable only if div that wraps the table overflows
    var containerRef = createRef();
    var _c = useState(), tabindex = _c[0], setTabindex = _c[1];
    useEffect(function () {
        var scrollable = containerRef.current
            ? containerRef.current.scrollWidth > containerRef.current.clientWidth
            : false;
        setTabindex(scrollable ? 0 : undefined);
    }, []);
    var captionId = useUniqueId();
    return (React.createElement(TableContainer, { tabIndex: tabindex, role: "group", "aria-labelledby": caption ? captionId : undefined, ref: containerRef },
        React.createElement(Table, __assign({}, rest, { ref: ref }),
            caption ? (React.createElement(TableCaption, { id: captionId }, caption)) : null,
            columnHeadings ? (React.createElement(TableHead, null,
                React.createElement(TableRow, null, columnHeadings.map(function (columnHeading, index) { return (React.createElement(TableHeader, { key: index, scope: "col", cellWidth: columnWidths ? columnWidths[index] : undefined }, columnHeading)); })))) : null,
            items ? (React.createElement(TableBody, null, items.map(function (rowData, p) { return (React.createElement(TableRow, { key: p, stripe: striped && p % 2 === 1 }, rowData.map(function (cellData, i) {
                return rowHeadings && i < 1 ? (React.createElement(TableRowHeader, { scope: "row", key: i, "data-testid": "rowHeader", cellWidth: columnWidths ? columnWidths[i] : undefined }, cellData)) : (React.createElement(TableCell, { key: i, cellWidth: columnWidths ? columnWidths[i] : undefined }, cellData));
            }))); }))) : null,
            children)));
});
var TableContainer = styled.div(function (_a) {
    var focusRing = _a.theme.focusRing;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    overflow-x: auto;\n    white-space: nowrap;\n    :focus {\n      ", "\n    }\n  "], ["\n    overflow-x: auto;\n    white-space: nowrap;\n    :focus {\n      ", "\n    }\n  "])), focusRing);
});
var Table = styled.table(function (_a) {
    var dataTable = _a.theme.dataTable;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border-radius: ", ";\n    border-collapse: separate;\n    width: 100%;\n    table-layout: auto;\n    white-space: nowrap;\n  "], ["\n    border-radius: ", ";\n    border-collapse: separate;\n    width: 100%;\n    table-layout: auto;\n    white-space: nowrap;\n  "])), dataTable.borderRadius);
});
export var TableHeader = styled.th(function (_a) {
    var _b = _a.theme, _c = _b.dataTable, tableCell = _c.tableCell, tableHeader = _c.tableHeader, text = _b.text, responsiveFontSizes = _b.responsiveFontSizes, mediaQueries = _b.mediaQueries, cellWidth = _a.cellWidth;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: ", " ", ";\n    font-weight: ", ";\n    text-align: left;\n    color: ", ";\n    background-color: ", ";\n    border-bottom: ", " solid ", ";\n    ", ";\n\n    font-family: ", ";\n    font-size: ", ";\n\n    ", " {\n      font-size: ", ";\n    }\n  "], ["\n    padding: ", " ", ";\n    font-weight: ", ";\n    text-align: left;\n    color: ", ";\n    background-color: ", ";\n    border-bottom: ", " solid ", ";\n    ", ";\n\n    font-family: ", ";\n    font-size: ", ";\n\n    ", " {\n      font-size: ", ";\n    }\n  "])), tableCell.paddingVertical, tableCell.paddingHorizontal, tableHeader.fontWeight, tableHeader.color, tableHeader.backgroundColor, tableHeader.borderWidth, tableHeader.borderColor, cellWidth ? "width: ".concat(cellWidth) : '', text.fontFamily, responsiveFontSizes.body[0], mediaQueries.smallAndUp, responsiveFontSizes.body[1]);
});
export var TableRowHeader = styled.th(function (_a) {
    var _b = _a.theme, dataTable = _b.dataTable, text = _b.text, responsiveFontSizes = _b.responsiveFontSizes, mediaQueries = _b.mediaQueries, cellWidth = _a.cellWidth;
    // Prevents a weird prettier issue.
    var tableCell = dataTable.tableCell, tableHeader = dataTable.tableHeader;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      padding: ", " ", ";\n      border-bottom: ", " solid ", ";\n      font-weight: ", ";\n      text-align: left;\n      color: ", ";\n      ", ";\n      font-family: ", ";\n      font-size: ", ";\n\n      ", " {\n        font-size: ", ";\n      }\n    "], ["\n      padding: ", " ", ";\n      border-bottom: ", " solid ", ";\n      font-weight: ", ";\n      text-align: left;\n      color: ", ";\n      ", ";\n      font-family: ", ";\n      font-size: ", ";\n\n      ", " {\n        font-size: ", ";\n      }\n    "])), tableCell.paddingVertical, tableCell.paddingHorizontal, dataTable.borderWidth, dataTable.borderColor, dataTable.tableHeader.fontWeight, tableHeader.color, cellWidth ? "width: ".concat(cellWidth) : '', text.fontFamily, responsiveFontSizes.body[0], mediaQueries.smallAndUp, responsiveFontSizes.body[1]);
});
export var TableCaption = styled.caption(function (_a) {
    var tableCaption = _a.theme.dataTable.tableCaption;
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-weight: ", ";\n    text-align: left;\n    color: ", ";\n    padding: ", " ", ";\n  "], ["\n    font-weight: ", ";\n    text-align: left;\n    color: ", ";\n    padding: ", " ", ";\n  "])), tableCaption.fontWeight, tableCaption.color, tableCaption.paddingVertical, tableCaption.paddingHorizontal);
});
export var TableHead = styled.thead(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
export var TableRow = styled.tr(function (_a) {
    var dataTable = _a.theme.dataTable, stripe = _a.stripe;
    return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    background-color: ", ";\n  "], ["\n    background-color: ", ";\n  "])), stripe
        ? dataTable.tableRow.stripeColor
        : dataTable.tableRow.backgroundColor);
});
export var TableBody = styled.tbody(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
export var TableCell = styled.td(function (_a) {
    var _b = _a.theme, tableCell = _b.dataTable.tableCell, mediaQueries = _b.mediaQueries, responsiveFontSizes = _b.responsiveFontSizes, text = _b.text, cellWidth = _a.cellWidth;
    return css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    font-weight: ", ";\n    font-family: ", ";\n    font-size: ", ";\n    padding: ", " ", ";\n    border-bottom: ", " solid ", ";\n    color: ", ";\n    ", ";\n\n    ", " {\n      font-size: ", ";\n    }\n  "], ["\n    font-weight: ", ";\n    font-family: ", ";\n    font-size: ", ";\n    padding: ", " ", ";\n    border-bottom: ", " solid ", ";\n    color: ", ";\n    ", ";\n\n    ", " {\n      font-size: ", ";\n    }\n  "])), text.fontWeight, text.fontFamily, responsiveFontSizes.body[0], tableCell.paddingVertical, tableCell.paddingHorizontal, tableCell.borderWidth, tableCell.borderColor, tableCell.color, cellWidth ? "width: ".concat(cellWidth) : '', mediaQueries.smallAndUp, responsiveFontSizes.body[1]);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
