import * as React from 'react';

export const GridIcon = ({ color = '#0A1B30', ...rest }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <line x1="21" y1="2" x2="21" y2="30" stroke={color} strokeWidth="2" />
      <line x1="11" y1="2" x2="11" y2="30" stroke={color} strokeWidth="2" />
      <line x1="2" y1="11" x2="30" y2="11" stroke={color} strokeWidth="2" />
      <line x1="2" y1="21" x2="30" y2="21" stroke={color} strokeWidth="2" />
    </svg>
  );
};
