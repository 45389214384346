import styled, { css } from 'styled-components';

export const Large = styled.span(
  ({ theme: { responsiveFontSizes, mediaQueries } }) => css`
    font-size: ${responsiveFontSizes.level4[0]};

    ${mediaQueries.smallAndUp} {
      font-size: ${responsiveFontSizes.level4[1]};
    }
  `,
);
