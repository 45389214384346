var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import { OverlayContainer, useModal, useOverlay, usePreventScroll, } from '@react-aria/overlays';
import React, { createRef, useContext, } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { remToNumber } from '../../utils';
import { Card } from '../Card';
import { Heading3 } from '../Heading';
import { Icon } from '../Icon';
import { VisuallyHidden } from '../VisuallyHidden';
var StyledOverlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  z-index: 100;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  background: rgba(0, 0, 0, 0.5);\n  overflow-y: auto;\n"], ["\n  position: fixed;\n  z-index: 100;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  background: rgba(0, 0, 0, 0.5);\n  overflow-y: auto;\n"])));
var StyledModal = styled(Card)(function (_a) {
    var _b = _a.theme, breakpoints = _b.breakpoints, mediaQueries = _b.mediaQueries, space = _b.space;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: relative;\n    max-width: 100%;\n    width: ", ";\n    min-height: calc(100vh - ", ");\n    margin: 0 auto;\n    margin-top: ", ";\n\n    ", " {\n      min-height: 0;\n    }\n\n    &:focus {\n      outline: none;\n    }\n  "], ["\n    position: relative;\n    max-width: 100%;\n    width: ", ";\n    min-height: calc(100vh - ", ");\n    margin: 0 auto;\n    margin-top: ", ";\n\n    ", " {\n      min-height: 0;\n    }\n\n    &:focus {\n      outline: none;\n    }\n  "])), breakpoints.medium, space[12], space[12], mediaQueries.smallAndUp);
});
var StyledModalWrapper = styled.div(function (_a) {
    var _b = _a.theme, grid = _b.grid, mediaQueries = _b.mediaQueries;
    return "\n    ".concat(mediaQueries.smallAndUp, " {\n      padding-left: ").concat(grid.gutter[1], ";\n      padding-right: ").concat(grid.gutter[1], ";\n    }\n  ");
});
var StyledHeading = styled.h2(function (_a) {
    var modal = _a.theme.modal;
    return "\n    padding-right: ".concat(modal.dismiss.size, ";\n  ");
});
// Returns the appropriate padding to align the icon itself with the card padding.
function iconDistance(padding, iconSize, touchSize) {
    var spaceAroundIcon = (remToNumber(touchSize) - remToNumber(iconSize)) / 2;
    return padding.map(function (pad) { return remToNumber(pad) - spaceAroundIcon + 'rem'; });
}
var StyledDismissButton = styled.button(function (_a) {
    var _b = _a.theme, focusRing = _b.focusRing, modal = _b.modal, mediaQueries = _b.mediaQueries, card = _b.card;
    var distance = iconDistance(card.padding, modal.dismiss.size, modal.dismiss.touchSize);
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      background: none;\n      border: none;\n      padding: 0;\n      width: ", ";\n      height: ", ";\n      position: absolute;\n      color: ", ";\n      top: ", ";\n      right: ", ";\n\n      ", " {\n        top: ", ";\n        right: ", ";\n      }\n\n      &:focus {\n        ", "\n      }\n      &:hover {\n        color: ", ";\n      }\n    "], ["\n      background: none;\n      border: none;\n      padding: 0;\n      width: ", ";\n      height: ", ";\n      position: absolute;\n      color: ", ";\n      top: ", ";\n      right: ", ";\n\n      ", " {\n        top: ", ";\n        right: ", ";\n      }\n\n      &:focus {\n        ", "\n      }\n      &:hover {\n        color: ", ";\n      }\n    "])), modal.dismiss.touchSize, modal.dismiss.touchSize, modal.dismiss.color, distance[0], distance[0], mediaQueries.smallAndUp, distance[1], distance[1], focusRing, modal.dismiss.colorHover);
});
var DismissButton = function (props) {
    var theme = useContext(ThemeContext);
    return (React.createElement(StyledDismissButton, __assign({}, props),
        React.createElement(Icon, { name: theme.modal.dismiss.icon, size: theme.modal.dismiss.size }),
        React.createElement(VisuallyHidden, null, "Dismiss")));
};
var ModalDialog = function (_a) {
    var title = _a.title, _b = _a.headingComponent, Heading = _b === void 0 ? Heading3 : _b, autoFocus = _a.autoFocus, props = __rest(_a, ["title", "headingComponent", "autoFocus"]);
    // Handle interacting outside the dialog and pressing
    // the Escape key to close the modal.
    var ref = createRef();
    var overlayProps = useOverlay(props, ref).overlayProps;
    // Prevent scrolling while the modal is open, and hide content
    // outside the modal from screen readers.
    usePreventScroll();
    var modalProps = useModal().modalProps;
    // Get props for the dialog and its title
    var _c = useDialog(props, ref), dialogProps = _c.dialogProps, titleProps = _c.titleProps;
    return (React.createElement(StyledOverlay, { "data-testid": "nebula-overlay" },
        React.createElement(FocusScope, { contain: true, restoreFocus: true, autoFocus: autoFocus },
            React.createElement(StyledModalWrapper, null,
                React.createElement(StyledModal, __assign({}, props, overlayProps, dialogProps, modalProps, { ref: ref }),
                    React.createElement(StyledHeading, __assign({ as: Heading }, titleProps), title),
                    React.createElement("div", null, props.children),
                    props.isDismissable ? (React.createElement(DismissButton, { onClick: props.onClose, "data-testid": "nebula-modal-dismiss" })) : null)))));
};
var Modal = function (_a) {
    var _b = _a.isDismissable, isDismissable = _b === void 0 ? true : _b, props = __rest(_a, ["isDismissable"]);
    return props.isOpen ? (React.createElement(OverlayContainer, null,
        React.createElement(ModalDialog, __assign({}, props, { isDismissable: isDismissable }), props.children))) : null;
};
export { Modal };
var templateObject_1, templateObject_2, templateObject_3;
