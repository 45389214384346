import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTheme } from '../hooks';

const LogoBase = (props: any) => {
  const { colors } = useTheme();

  return (
    <svg height="48" viewBox="0 0 173 170" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M86.1 69.84A14.69 14.69 0 0193.83 72a14.77 14.77 0 01-8 27.35 14.45 14.45 0 01-7.62-2.16 14.8 14.8 0 017.89-27.35zm0-11a25.8 25.8 0 00-13.7 47.7 25.391 25.391 0 0013.48 3.85 25.762 25.762 0 0024.983-18.583A25.763 25.763 0 0099.7 62.74a25.59 25.59 0 00-13.6-3.9z"
          fill={colors.primary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.28 109.3a46.54 46.54 0 00-4.37-55.3 11.437 11.437 0 00-4.17-14 11.001 11.001 0 00-13.52 1.46 46.39 46.39 0 00-41.83 82.4 44.005 44.005 0 0020.19 6.87 10.218 10.218 0 004.42 5.41 10.819 10.819 0 0015-3.54 10.12 10.12 0 001.66-4.58 46.671 46.671 0 0022.62-18.72zm-20.19-50.78a11 11 0 0012.28-.41 41.22 41.22 0 013.33 48.27 40.639 40.639 0 01-18.94 16 11.407 11.407 0 00-4.16-4.79 10.819 10.819 0 00-15 3.54 10.367 10.367 0 00-1.6 4.19 43.172 43.172 0 01-16.64-6 40.928 40.928 0 016.706-72.572 40.93 40.93 0 0129.294-.428 10.88 10.88 0 004.73 12.2z"
          fill={colors.heading}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M166.89 64.35a13.157 13.157 0 003.54-3.35 11.103 11.103 0 00-3.54-15.19 11.471 11.471 0 00-7.7-1.46A83.999 83.999 0 00129.65 13a85.13 85.13 0 00-94.89 3.12h-.21a11.05 11.05 0 00-15.19 3.28 11.28 11.28 0 000 11.45 96.24 96.24 0 00-6.45 8.95 84.43 84.43 0 0016.65 109 10.92 10.92 0 004.16 13.74 10.996 10.996 0 0013.94-1.88 84.592 84.592 0 0086.34-7.25l.62.21a10.798 10.798 0 0015-3.33 11.176 11.176 0 00.21-11.65 79.946 79.946 0 006.66-9.16 85.15 85.15 0 0010.4-65.13zM50.16 155.91a10.78 10.78 0 00-4.79-11.86 11.327 11.327 0 00-12.48.41 79.17 79.17 0 01-15.4-102c1.87-2.7 3.74-5.41 5.62-7.7a11.26 11.26 0 0015-3.74 11.13 11.13 0 00.41-10.82 79.13 79.13 0 0188.23-2.71 80.093 80.093 0 0127.45 29.17 14.578 14.578 0 00-2.29 2.71 10.798 10.798 0 003.33 15 10.359 10.359 0 006.45 1.63 78.46 78.46 0 01-10 60.56 59.407 59.407 0 01-5.82 8.32 10.997 10.997 0 00-14.78 3.75 11.306 11.306 0 00-.62 10.61 79.38 79.38 0 01-80.31 6.67z"
          fill={colors.heading}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.66 29.39a66.17 66.17 0 00-35.79-10 11.52 11.52 0 00-4.16-4.78 11.12 11.12 0 00-15.2 3.53 11.82 11.82 0 00-1.66 5.21A65 65 0 0019.57 81a9.19 9.19 0 00-5.83 4.57 10.81 10.81 0 003.33 15 13.679 13.679 0 004.58 1.66 65.55 65.55 0 1098-72.83l.01-.01zm-51.61 4a11 11 0 0015-3.53A8.35 8.35 0 0084.7 25a60.002 60.002 0 0159.831 65.503 60.002 60.002 0 01-70.79 53.472 59.999 59.999 0 01-46.68-42.605A10.589 10.589 0 0032.26 97a10.847 10.847 0 00-3.54-15A9.191 9.191 0 0025 80.58a58.709 58.709 0 019-27.47 59.75 59.75 0 0130-24.34 12.27 12.27 0 004.05 4.57v.05z"
          fill={colors.heading}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h172.06v169.26H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Logo = styled(LogoBase)`
  a:focus &,
  :hover {
    animation: ${rotate} 2.5s linear infinite;
  }
`;
