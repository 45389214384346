var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { remToNumber } from '../../utils';
export var Tab = styled(forwardRef(
// eslint-disable-next-line
function (_a, ref) {
    var active = _a.active, fullWidth = _a.fullWidth, rest = __rest(_a, ["active", "fullWidth"]);
    return React.createElement("li", __assign({ role: "tab", ref: ref }, rest));
}))(function (_a) {
    var _b = _a.theme, focusRing = _b.focusRing, mediaQueries = _b.mediaQueries, tabs = _b.tabs, card = _b.card, active = _a.active, fullWidth = _a.fullWidth;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: ", ";\n    font-family: ", ";\n    font-weight: ", ";\n    line-height: ", ";\n    padding: ", " ", ";\n    color: ", ";\n    flex-grow: 1;\n    text-align: center;\n    background-color: ", ";\n    position: relative;\n    border: ", " solid ", ";\n    border-bottom-color: ", ";\n    overflow: hidden;\n    cursor: pointer;\n    padding-top: ", "rem;\n    ", " {\n      font-size: ", ";\n      padding: ", " ", ";\n    }\n\n    &:first-child {\n      border-top-left-radius: ", ";\n    }\n    &:last-child {\n      border-top-right-radius: ", ";\n    }\n\n    ", ";\n\n    &:before {\n      content: '';\n      position: absolute;\n      background-color: ", ";\n      height: ", ";\n      top: 0;\n      left: 0;\n      right: 0;\n    }\n\n    &:focus {\n      ", ";\n      position: relative;\n      z-index: 10;\n    }\n\n    ", "\n  "], ["\n    font-size: ", ";\n    font-family: ", ";\n    font-weight: ", ";\n    line-height: ", ";\n    padding: ", " ", ";\n    color: ", ";\n    flex-grow: 1;\n    text-align: center;\n    background-color: ", ";\n    position: relative;\n    border: ", " solid ", ";\n    border-bottom-color: ", ";\n    overflow: hidden;\n    cursor: pointer;\n    padding-top: ", "rem;\n    ", " {\n      font-size: ", ";\n      padding: ", " ", ";\n    }\n\n    &:first-child {\n      border-top-left-radius: ", ";\n    }\n    &:last-child {\n      border-top-right-radius: ", ";\n    }\n\n    ", ";\n\n    &:before {\n      content: '';\n      position: absolute;\n      background-color: ", ";\n      height: ", ";\n      top: 0;\n      left: 0;\n      right: 0;\n    }\n\n    &:focus {\n      ", ";\n      position: relative;\n      z-index: 10;\n    }\n\n    ", "\n  "])), tabs.tab.fontSize[0], tabs.tab.fontFamily, tabs.tab.fontWeight, tabs.tab.lineHeight, tabs.tab.paddingVertical[0], tabs.tab.paddingHorizontal[0], active
        ? tabs.tab.active.foregroundColor
        : tabs.tab.inactive.foregroundColor, active
        ? tabs.tab.active.backgroundColor
        : tabs.tab.inactive.backgroundColor, card.borderWidth, card.borderColor, active
        ? tabs.tab.active.backgroundColor
        : card.borderColor, remToNumber(tabs.tab.borderTopWidth) +
        remToNumber(tabs.tab.paddingVertical[0]), mediaQueries.smallAndUp, tabs.tab.fontSize[1], tabs.tab.paddingVertical[1], tabs.tab.paddingHorizontal[1], card.borderRadius, card.borderRadius, fullWidth === 'small'
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          ", " {\n            flex-grow: 0;\n            &:last-child {\n              border-top-right-radius: 0;\n            }\n          }\n        "], ["\n          ", " {\n            flex-grow: 0;\n            &:last-child {\n              border-top-right-radius: 0;\n            }\n          }\n        "])), mediaQueries.smallAndUp) : fullWidth === 'never'
        ? css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          flex-grow: 0;\n          &:last-child {\n            border-top-right-radius: 0;\n          }\n        "], ["\n          flex-grow: 0;\n          &:last-child {\n            border-top-right-radius: 0;\n          }\n        "]))) : '', active
        ? tabs.tab.active.borderTopColor
        : 'transparent', tabs.tab.borderTopWidth, css(focusRing), active
        ? ''
        : css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          &:hover {\n            color: ", ";\n            text-decoration: underline;\n          }\n        "], ["\n          &:hover {\n            color: ", ";\n            text-decoration: underline;\n          }\n        "])), tabs.tab.hover.foregroundColor));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
