import dracula from 'prism-react-renderer/themes/dracula';
import React from 'react';
import { LiveProvider, LiveEditor } from 'react-live';
import styled from 'styled-components';
import { PreviewPanel } from './PreviewPanel';

const CodeBlockWrapper = styled.div(
  ({ theme: { space, radii } }) => `
    background:${dracula.plain.backgroundColor};
    border-radius: ${radii.standard};
    padding: ${space[3]} ${space[6]};
  `,
);

export const CodeBlock = ({ code, live = false }: any) => {
  return live ? (
    <PreviewPanel code={code.replace(/^\s+|\s+$/g, '')} />
  ) : (
    <CodeBlockWrapper>
      <LiveProvider
        code={code.replace(/^\s+|\s+$/g, '')}
        theme={dracula}
        disabled
      >
        <LiveEditor style={{ margin: '-10px', fontSize: '16px' }} />
      </LiveProvider>
    </CodeBlockWrapper>
  );
};
