import * as React from 'react';

export const SpaceIcon = ({ color = '#0A1B30', ...rest }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path d="M22 2L30 2V10L22 10V2Z" fill={color} fillOpacity="0.5" />
      <path d="M12 8L24 8V20H12L12 8Z" fill={color} fillOpacity="0.75" />
      <path d="M2 14L18 14L18 30L2 30L2 14Z" fill={color} />
    </svg>
  );
};
