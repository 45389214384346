var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Pound = function (props) { return (React.createElement("svg", __assign({ viewBox: "0 0 16 16", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M6.19 13.544h7.459V16H1.333v-2.456h2.17V9.037h-2.17V7.189h2.17V6.152c0-1.247.248-2.329.744-3.245a5.136 5.136 0 012.21-2.14C7.423.254 8.58 0 9.93 0 11.57 0 12.925.353 14 1.06l-.847 2.388c-.84-.586-1.963-.879-3.368-.879-1.171 0-2.067.308-2.687.924-.606.6-.909 1.48-.909 2.637v1.059h5.228v1.848H6.19v4.507z", fill: "currentColor" }))); };
