import * as React from 'react';

export const StackIcon = ({ color = '#0A1B30', ...rest }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <line x1="2" y1="26" x2="30" y2="26" stroke={color} strokeWidth="8" />
      <line
        x1="2"
        y1="16"
        x2="30"
        y2="16"
        stroke={color}
        strokeOpacity="0.75"
        strokeWidth="8"
      />
      <line
        x1="2"
        y1="6"
        x2="30"
        y2="6"
        stroke={color}
        strokeOpacity="0.5"
        strokeWidth="8"
      />
    </svg>
  );
};
