import styled, { css } from 'styled-components';

export const Container = styled.div(
  ({ theme: { space, breakpoints, mediaQueries } }) => css`
    margin: 0 auto;
    max-width: 100%;
    width: ${breakpoints.large};
    padding-left: ${space[4]};
    padding-right: ${space[4]};

    ${mediaQueries.smallAndUp} {
      padding-left: ${space[8]};
      padding-right: ${space[8]};
    }
  `,
);
