var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { StyledInput } from './Input';
var StyledTextarea = styled.textarea(function (_a) {
    var input = _a.theme.input, resizable = _a.resizable;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    min-height: ", ";\n    height: auto;\n    max-width: 100%;\n    display: block;\n    resize: ", ";\n  "], ["\n    min-height: ", ";\n    height: auto;\n    max-width: 100%;\n    display: block;\n    resize: ", ";\n  "])), input.height, resizable ? 'vertical' : 'none');
});
var TextareaInput = forwardRef(function (_a, ref) {
    var _b = _a.rows, rows = _b === void 0 ? 3 : _b, _c = _a.resizable, resizable = _c === void 0 ? false : _c, rest = __rest(_a, ["rows", "resizable"]);
    return (React.createElement(StyledInput, __assign({}, rest, { as: StyledTextarea, rows: rows, resizable: resizable, ref: ref })));
});
export { TextareaInput };
var templateObject_1;
