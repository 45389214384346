import * as React from 'react';

export const AccessibilityIcon = ({ color = '#0A1B30', ...rest }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <line x1="16" y1="11" x2="16" y2="22" stroke={color} strokeWidth="8" />
      <line x1="4" y1="12.5" x2="28" y2="12.5" stroke={color} strokeWidth="3" />
      <line
        x1="13.75"
        y1="22"
        x2="13.75"
        y2="30"
        stroke={color}
        strokeWidth="3.5"
      />
      <line
        x1="18.25"
        y1="22"
        x2="18.25"
        y2="30"
        stroke={color}
        strokeWidth="3.5"
      />
      <circle cx="16" cy="6" r="4" fill={color} />
    </svg>
  );
};
