var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useIcons } from '../../providers';
export var Icon = function (_a) {
    var name = _a.name, _b = _a.size, size = _b === void 0 ? '1em' : _b, rest = __rest(_a, ["name", "size"]);
    var icons = useIcons();
    // @ts-ignore to support additional icons.
    var Svg = name && icons[name];
    return Svg ? (React.createElement(Svg, __assign({ "data-testid": "nebula-icon".concat(name ? "-".concat(name) : ''), "data-icon": name, width: size, height: size, focusable: "false", "aria-hidden": "true" }, rest))) : null;
};
