var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Solar = function (props) { return (React.createElement("svg", __assign({ viewBox: "0 0 24 24" }, props, { xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.83261 4.871C4.89529 4.51199 5.20699 4.25 5.57143 4.25H12H18.4285C18.793 4.25 19.1047 4.51199 19.1674 4.871L21.7388 19.5983C21.7769 19.8164 21.7166 20.0403 21.5741 20.2098C21.4316 20.3794 21.2215 20.4773 21 20.4773H12H3C2.77852 20.4773 2.56837 20.3794 2.42587 20.2098C2.28336 20.0403 2.22308 19.8164 2.26118 19.5983L4.83261 4.871ZM17.2228 18.9773H20.1077L19.7267 16.7955H17.0303L17.2228 18.9773ZM15.717 18.9773L15.5245 16.7955H12.75V18.9773H15.717ZM16.898 15.2955H19.4648L19.0839 13.1136H16.7055L16.898 15.2955ZM15.1996 13.1136L15.3921 15.2955H12.75V13.1136H15.1996ZM16.5731 11.6136H18.822L18.441 9.43182H16.3806L16.5731 11.6136ZM14.8748 9.43182L15.0673 11.6136H12.75V9.43182H14.8748ZM16.2482 7.93182H18.1791L17.7981 5.75H16.0557L16.2482 7.93182ZM14.5499 5.75L14.7424 7.93182H12.75V5.75H14.5499ZM11.25 16.7955V18.9773H8.28299L8.47551 16.7955H11.25ZM3.8923 18.9773H6.77716L6.96968 16.7955H4.27325L3.8923 18.9773ZM11.25 15.2955H8.60786L8.80037 13.1136H11.25V15.2955ZM7.10203 15.2955L7.29454 13.1136H4.91611L4.53516 15.2955H7.10203ZM11.25 11.6136H8.93272L9.12524 9.43182H11.25V11.6136ZM7.4269 11.6136L7.61941 9.43182H5.55897L5.17801 11.6136H7.4269ZM11.25 7.93182H9.25759L9.4501 5.75H11.25V7.93182ZM7.75176 7.93182L7.94428 5.75H6.20182L5.82087 7.93182H7.75176Z", fill: "currentColor" }))); };
