import * as React from 'react';

export const MotionIcon = ({ color = '#0A1B30', ...rest }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M6 12C6 6.47715 10.4772 2 16 2V2C21.5228 2 26 6.47715 26 12V12C26 17.5228 21.5228 22 16 22V22C10.4772 22 6 17.5228 6 12V12Z"
        fill={color}
      />
      <path
        d="M2 27L7 29.8868L7 24.1132L2 27ZM30 27L25 24.1132V29.8868L30 27ZM6.5 27.5L25.5 27.5V26.5L6.5 26.5V27.5Z"
        fill={color}
        fillOpacity={0.5}
      />
    </svg>
  );
};
