var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var LabelText = styled.span(function (_a) {
    var _b = _a.theme, field = _b.field, mediaQueries = _b.mediaQueries;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-family: ", ";\n    line-height: ", ";\n    display: block;\n    font-weight: ", ";\n    color: ", ";\n    font-size: ", ";\n\n    ", " {\n      font-size: ", ";\n    }\n  "], ["\n    font-family: ", ";\n    line-height: ", ";\n    display: block;\n    font-weight: ", ";\n    color: ", ";\n    font-size: ", ";\n\n    ", " {\n      font-size: ", ";\n    }\n  "])), field.fontFamily, field.lineHeight, field.label.fontWeight, field.label.color, field.fontSize[0], mediaQueries.smallAndUp, field.fontSize[1]);
});
export { LabelText };
var templateObject_1;
