import * as React from 'react';

export const BordersIcon = ({ color = '#0A1B30', ...rest }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...rest}>
      <path
        d="M7 7L25 7L25 25L7 25L7 7Z"
        stroke={color}
        strokeOpacity={0.75}
        strokeWidth={2}
      />
      <path
        d="M11 11L21 11V21H11L11 11Z"
        fill={color}
        stroke={color}
        strokeWidth={2}
      />
      <path
        d="M3 3L29 3L29 29L3 29L3 3Z"
        stroke={color}
        strokeOpacity={0.5}
        strokeWidth={2}
      />
    </svg>
  );
};
