var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { createRef, useEffect } from 'react';
import styled from 'styled-components';
import { Ul, Li } from '../List';
import { ErrorNotification } from '../Notification';
import { TextLink } from '../TextLink';
var ErrorTextLink = styled(TextLink)(function (_a) {
    var errorNotification = _a.theme.errorNotification;
    return "\n    color: ".concat(errorNotification.summaryLink.color, ";\n\n    &:hover {\n      color: ").concat(errorNotification.summaryLink.hover, ";\n    }\n  ");
});
export var ErrorSummaryNotification = function (_a) {
    var id = _a.id, title = _a.title, errors = _a.errors, rest = __rest(_a, ["id", "title", "errors"]);
    var ref = createRef();
    useEffect(function () {
        if (errors.length && ref.current) {
            ref.current.focus();
        }
        // This means that errors needs to have referential integrity otherwise the notification
        // will be focused all the time.
    }, [errors, ref.current]);
    return (React.createElement(ErrorNotification, __assign({ id: id, title: title, ref: ref }, rest), errors.length === 0 ? null : (React.createElement(Ul, null, errors.map(function (_a) {
        var errorId = _a.id, message = _a.message;
        return (React.createElement(Li, { key: errorId },
            React.createElement(ErrorTextLink, { href: "#".concat(errorId) }, message)));
    })))));
};
