var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Cross = function (props) { return (React.createElement("svg", __assign({ viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M3.15 1.05l-1-1L0 2.06l1 1 5 5-4.84 4.83-1 1L2.23 16l1-1.05 4.79-4.8 4.67 4.69 1 1 2.11-2.06-1-1.05-4.7-4.71L15 3.23l1-1L13.89.12l-1 1L8.06 6z", fill: "currentColor" }))); };
