import { Col, Heading1, P, Row } from '@ovotech/nebula';
import React, { ReactNode } from 'react';
import { Large, PageContent, CustomHero } from '../components';

export const PageLayout = ({
  title,
  summary = '',
  children,
}: {
  title: string;
  summary: string;
  children: ReactNode;
}) => {
  return (
    <>
      <CustomHero>
        <Row>
          <Col>
            <Heading1>{title}</Heading1>
            <P>
              <Large>{summary}</Large>
            </P>
          </Col>
        </Row>
      </CustomHero>
      <PageContent>{children}</PageContent>
    </>
  );
};

export default PageLayout;
