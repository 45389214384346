import { Heading4, Ul, Li, TextLink, Badge } from '@ovotech/nebula';
import { Link } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';
import { useSitemap } from '../hooks/use-sitemap';
import '@ovotech/nebula/dist/reset.css';

const MenuList = styled(Ul)`
  list-style: none;
  margin-left: 0;
`;

const MenuListItem = styled(Li)``;

const MenuListLink = styled(Link)(
  ({ theme: { colors, fontWeights, space } }) => css`
    padding-left: ${space[4]};
    border-left: 3px;
    border-left-style: transparent;
    border-left-color: ${colors.primary};

    &.active {
      font-weight: ${fontWeights.bold};
      border-left-style: solid;
      padding-left: calc(${space[4]} - 3px);
    }
  `,
);

export const SubMenu = ({ showAbout = false }: { showAbout?: boolean }) => {
  const menuLinks = useSitemap();
  return (
    <>
      {menuLinks
        .filter((item: any) => !(!showAbout && item.title === 'About'))
        .map((section: any) => (
          <React.Fragment key={section.title}>
            <Heading4>{section.title}</Heading4>
            <MenuList key={section.title}>
              {section.links.map((page: any) => (
                <MenuListItem key={page.path}>
                  <TextLink
                    as={MenuListLink}
                    to={page.path}
                    activeClassName="active"
                  >
                    {page.title}
                  </TextLink>
                  {page.new ? (
                    <>
                      {' '}
                      <Badge>New</Badge>
                    </>
                  ) : null}
                </MenuListItem>
              ))}
            </MenuList>
          </React.Fragment>
        ))}
    </>
  );
};
