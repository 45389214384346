var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { useResponsiveStyle } from '../../hooks';
var Stack = styled.div(function (_a) {
    var space = _a.theme.space, spaceBetween = _a.spaceBetween;
    var formatter = function (spaceKey) { return space[spaceKey]; };
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    > * + * {\n      ", "\n    }\n  "], ["\n    > * + * {\n      ", "\n    }\n  "])), useResponsiveStyle('margin-top', spaceBetween, formatter));
});
export { Stack };
var templateObject_1;
