import { useStaticQuery, graphql } from 'gatsby';

const titleCase = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1).replace(/-/g, ' ');

export const useSitemap = () => {
  const {
    allMdx: { group },
  } = useStaticQuery(graphql`
    {
      allMdx(
        sort: { frontmatter: { slug: ASC } }
        filter: { fields: { source: { nin: ["changelog"] } } }
      ) {
        group(field: { fields: { source: SELECT } }) {
          fieldValue
          edges {
            node {
              id
              frontmatter {
                title
                summary
                slug
              }
            }
          }
        }
      }
    }
  `);

  return group.map(({ fieldValue, edges }: any) => {
    const links = edges.map(({ node }: any) => {
      return {
        title: titleCase(node.frontmatter.title),
        path: `/${fieldValue}/${node.frontmatter.slug}`,
      };
    });
    return {
      title: titleCase(fieldValue),
      source: fieldValue,
      links,
    };
  });
};
