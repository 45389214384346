var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';
var CalloutWrapper = styled.div(function (_a) {
    var callout = _a.theme.callout, _b = _a.variant, variant = _b === void 0 ? 'info' : _b;
    return "\n    position: relative;\n    background-color: ".concat(callout.variants[variant].background, ";\n    padding-left: ").concat(callout.lineIndentWidth, ";\n    border-radius: ").concat(callout.borderRadius, ";\n  ");
});
var LineIndent = styled.div(function (_a) {
    var callout = _a.theme.callout, _b = _a.variant, variant = _b === void 0 ? 'info' : _b;
    return "\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    width: ".concat(callout.lineIndentWidth, ";\n    border-radius: ").concat(callout.borderRadius, " 0 0 ").concat(callout.borderRadius, ";\n    background-color: ").concat(callout.variants[variant].foreground, ";\n  ");
});
var CalloutContent = styled.div(function (_a) {
    var _b = _a.theme, callout = _b.callout, mediaQueries = _b.mediaQueries;
    return "\n    display: flex;\n    gap: ".concat(callout.iconGap, ";\n    padding: ").concat(callout.paddingVertical[0], " ").concat(callout.paddingHorizontal[0], ";\n\n    ").concat(mediaQueries.smallAndUp, " {\n      padding: ").concat(callout.paddingVertical[1], " ").concat(callout.paddingHorizontal[1], ";\n    }\n  ");
});
var StyledIcon = styled(Icon)(function (_a) {
    var _b = _a.theme, callout = _b.callout, mediaQueries = _b.mediaQueries, _c = _a.variant, variant = _c === void 0 ? 'info' : _c;
    return "\n    flex-shrink: 0;\n    font-size: ".concat(callout.iconSize[0], ";\n    color: ").concat(callout.variants[variant].foreground, ";\n\n    ").concat(mediaQueries.smallAndUp, " {\n      font-size: ").concat(callout.iconSize[1], ";\n    }\n  ");
});
var Callout = forwardRef(function (_a, ref) {
    var variant = _a.variant, icon = _a.icon, children = _a.children, rest = __rest(_a, ["variant", "icon", "children"]);
    return (React.createElement(CalloutWrapper, __assign({ variant: variant }, rest, { ref: ref }),
        React.createElement(LineIndent, { "aria-hidden": "true", variant: variant }),
        React.createElement(CalloutContent, null,
            icon && React.createElement(StyledIcon, { name: icon, variant: variant }),
            React.createElement("div", null, children))));
});
export { Callout };
