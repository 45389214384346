var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var Stretch = styled.div(function (_a) {
    var mediaQueries = _a.theme.mediaQueries, fullWidth = _a.fullWidth, _b = _a.defaultWidth, defaultWidth = _b === void 0 ? 'auto' : _b;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100%;\n    ", "\n  "], ["\n    width: 100%;\n    ", "\n  "])), fullWidth === 'small'
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          ", " {\n            width: ", ";\n          }\n        "], ["\n          ", " {\n            width: ", ";\n          }\n        "])), mediaQueries.smallAndUp, defaultWidth) : fullWidth === 'never'
        ? "\n            width: ".concat(defaultWidth, ";\n          ")
        : '');
});
var templateObject_1, templateObject_2;
