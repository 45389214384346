import AccessAlarm from '@material-ui/icons/AccessAlarm';
import { MDXProvider } from '@mdx-js/react';
import {
  P,
  TextLink,
  VisuallyHidden,
  Heading1,
  Heading2,
  Ul,
  Ol,
  Li,
  Heading3,
  Heading4,
  Strong,
  Card,
  Row,
  Col,
  Badge,
  Disclosure,
  Stack,
  SelectField,
  remToNumber,
  IconsProvider,
  Margin,
  Callout,
} from '@ovotech/nebula';
import corePackageJson from '@ovotech/nebula/package.json';
import { OverlayProvider } from '@react-aria/overlays';
import { Link } from 'gatsby';
import React, { FunctionComponent, useEffect, useState } from 'react';
import '@ovotech/nebula/dist/reset.css';
import { Helmet } from 'react-helmet';
import styled, { ThemeProvider, css } from 'styled-components';

import Tree from '../../svg/tree.svg';

import {
  CodeBlock,
  InlineCode,
  Large,
  PropsTable,
  PreviewPanel,
  MenuLink,
  TitleBar,
  CaptionedImage,
  Inline,
  Block,
  Text,
  ThemeTable,
  Sidebar,
  SkipLink,
} from '../components';
import { useThemes, useSitemap } from '../hooks';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import { FigmaIcon, GithubIcon, SlackIcon } from './Icons';
import { Logo } from './Logo';
import { MobileNav } from './MobileNav';

import '../style.css';

function isExternalLink(url: string | undefined): boolean {
  return !!url && /^https?:\/\//.test(url);
}

const shortcodes = {
  h1: Heading1,
  h2: Heading2,
  p: P,
  ul: Ul,
  ol: Ol,
  li: Li,
  h3: Heading3,
  a: ({ href, children }: any) => {
    // Fixes github links in changelog.
    const updatedLink = href.replace(
      'http://@ovotech',
      'http://github.com/ovotech',
    );
    return !isExternalLink(href) ? (
      <TextLink as={Link} to={href}>
        {children}
      </TextLink>
    ) : (
      <TextLink href={updatedLink} opensInNewWindow>
        {children}
      </TextLink>
    );
  },
  h4: Heading4,
  code: ({ children, className }: any) =>
    className ? (
      <CodeBlock code={children} live={className.includes(':live')} />
    ) : (
      <InlineCode>{children}</InlineCode>
    ),
  strong: Strong,
  blockquote: (props: any) => (
    <Margin vertical={[2, 3]}>
      <Callout {...props} />
    </Margin>
  ),
  Summary: ({ children }: any) => (
    <P>
      <Large>{children}</Large>
    </P>
  ),
  PropsTable,
  PreviewPanel,
  Badge,
  P,
  CaptionedImage,
  ThemeTable,
  Block,
  Inline,
  Card,
  Text,
  Disclosure,
  Stack,
  Strong,
};

const TitleBarContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

const TitleBarSection = styled.div(
  ({ theme: { space, mediaQueries, lineHeights, responsiveFontSizes } }) => css`
    display: none;
    justify-items: center;
    align-items: center;
    height: ${remToNumber(responsiveFontSizes.body[0]) *
      lineHeights.standard}rem;

    ${mediaQueries.largeAndUp} {
      display: flex;
      height: ${remToNumber(responsiveFontSizes.body[1]) *
        lineHeights.standard}rem;
    }

    > * + * {
      margin-left: ${space[6]};
    }
  `,
);

const SmallTitleBarSection = styled.div(
  ({ theme: { mediaQueries } }) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;

    ${mediaQueries.largeAndUp} {
      display: none;
    }
  `,
);

const Main = styled.main(
  ({ theme: { colors, mediaQueries } }) => css`
    background-color: ${colors.canvas};
    padding-top: 8rem;
    flex-grow: 1;

    ${mediaQueries.largeAndUp} {
      padding-top: 0;
      margin-top: 8.8rem;
    }
  `,
);

const AppContainer = styled.div<{ isSidebarOpen: boolean }>(
  ({ theme: { mediaQueries }, isSidebarOpen }) => css`
    display: flex;
    flex-direction: column;
    height: 100vh;

    ${mediaQueries.largeAndUp} {
      padding-left: ${isSidebarOpen ? '25rem' : 0};
    }
  `,
);

const LogoLink = styled(Link)(
  ({ theme: { focusRing } }) => css`
    &:focus {
      ${css(focusRing)}
      overflow: hidden;
    }
    height: 4.8rem;
    svg {
      height: 4.8rem;
    }
  `,
);

const ContentsList = styled(Ul)`
  list-style: none;
`;

const ContentsListItem = styled(Li)``;

const ContentsLink = styled(TextLink)(
  ({ theme: { colors, borderWidths, radii, fontWeights, focusRing } }) => css`
    font-weight: ${fontWeights.normal};
    color: ${colors.body};
    span {
      text-decoration: none;
    }

    &:focus {
      ${css(focusRing)}
    }

    &.active {
      font-weight: ${fontWeights.bold};

      &:before {
        background: ${colors.primary};
      }
    }

    &:before {
      content: '';
      width: ${borderWidths.heavy};
      background: none;
      border-radius: ${radii.standard};
      position: absolute;
      right: 100%;
      height: 1.5em;
    }
  `,
);

const Contents = styled.div(
  ({ theme: { borderWidths, radii, colors, space } }) => `
    &:before {
      content: '';
      width: ${borderWidths.heavy};
      background: ${colors.canvasMuted};
      border-radius: ${radii.standard};
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;

    }
    padding-left: ${space[4]};
    margin-left: ${space[2]};
    position: relative;
    & > ${Ul} {
      margin-left: 0;
    }
  `,
);

const Footer = styled.footer(
  ({ theme: { colors, mediaQueries, space } }) => `
      padding: ${space[10]};
      padding-bottom: ${space[10]};
      background: ${colors.canvasMuted};

      ${mediaQueries.smallAndUp} {
        padding-top: ${remToNumber(space[12]) + remToNumber(space[4])}rem;
        padding-bottom: ${remToNumber(space[12]) + remToNumber(space[4])}rem;
      }
    `,
);

const mapMaterialIcon = (Component: FunctionComponent) => ({
  color,
  width,
  style,
  ...rest
}: any) => <Component style={{ color, fontSize: width, ...style }} {...rest} />;

const Layout = ({ children, location }: any) => {
  const { title } = useSiteMetadata();
  const themes = useThemes();
  const [selectedTheme, setSelectedTheme] = useState('nebula-light');

  useEffect(() => {
    const themeFromQ =
      typeof URLSearchParams !== 'undefined' && typeof window !== 'undefined'
        ? new URLSearchParams(window.location.search).get('theme')
        : null;

    const themeFromLS =
      typeof localStorage !== 'undefined' &&
      localStorage.getItem('themeSelection');

    const theme = themeFromQ || themeFromLS || 'nebula-light';

    if (themeFromQ && themes[themeFromQ as keyof typeof themes]) {
      typeof localStorage !== 'undefined' &&
        themeFromQ &&
        localStorage.setItem('themeSelection', themeFromQ);
    }

    if (themes[theme as keyof typeof themes]) {
      setSelectedTheme(theme);
    }
  }, []);

  const sitemap = useSitemap();
  const sidebarLinks = sitemap
    .filter((section: any) => location.pathname.includes(section.source))
    .reduce((_: any, cur: any) => ({ ...cur }), {});

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
      </Helmet>
      <IconsProvider
        icons={{
          tree: Tree,
          alarm: mapMaterialIcon(AccessAlarm),
        }}
      >
        <ThemeProvider theme={themes[selectedTheme as keyof typeof themes]}>
          <OverlayProvider>
            <MDXProvider components={shortcodes}>
              <AppContainer isSidebarOpen={sidebarLinks.links}>
                <SkipLink href="#main" fullWidth="never">
                  Skip to content
                </SkipLink>
                <TitleBar>
                  <TitleBarContent>
                    <SmallTitleBarSection>
                      <LogoLink to="/">
                        <Logo />
                        <VisuallyHidden>Nebula</VisuallyHidden>
                      </LogoLink>
                      <MobileNav />
                    </SmallTitleBarSection>
                    <TitleBarSection as="nav" aria-label="site">
                      <LogoLink to="/">
                        <Logo />
                        <VisuallyHidden>Nebula</VisuallyHidden>
                      </LogoLink>
                      <MenuLink
                        to="/get-started"
                        as={Link}
                        activeClassName="active"
                        partiallyActive={true}
                      >
                        Get Started
                      </MenuLink>
                      <MenuLink
                        to="/foundations"
                        as={Link}
                        activeClassName="active"
                        partiallyActive={true}
                      >
                        Foundations
                      </MenuLink>
                      <MenuLink
                        to="/components"
                        as={Link}
                        activeClassName="active"
                        partiallyActive={true}
                      >
                        Components
                      </MenuLink>
                      <MenuLink
                        to="/guides"
                        as={Link}
                        activeClassName="active"
                        partiallyActive={true}
                      >
                        Guides
                      </MenuLink>
                    </TitleBarSection>
                    <TitleBarSection>
                      <Text>
                        <TextLink
                          href="https://forms.gle/bsBsmkszp85Nvi3MA"
                          opensInNewWindow
                        >
                          Feedback
                        </TextLink>
                      </Text>
                      <Text>
                        <TextLink
                          to="/changelog"
                          as={Link}
                          activeClassName="active"
                          partiallyActive={true}
                        >
                          v{corePackageJson.version}{' '}
                          <VisuallyHidden>Change Log</VisuallyHidden>
                        </TextLink>
                      </Text>
                      <Text>
                        <TextLink
                          href="https://github.com/ovotech/nebula"
                          target="_blank"
                        >
                          <GithubIcon />
                          <VisuallyHidden>Visit Github Project</VisuallyHidden>
                        </TextLink>
                      </Text>
                      <Text>
                        <TextLink href="https://figma.com" target="_blank">
                          <FigmaIcon />
                          <VisuallyHidden>Visit Figma Project</VisuallyHidden>
                        </TextLink>
                      </Text>
                      <Text>
                        <TextLink
                          href="https://ovoenergy.slack.com/archives/CRVLLPUJX"
                          target="_blank"
                        >
                          <SlackIcon />
                          <VisuallyHidden>Slack Channel</VisuallyHidden>
                        </TextLink>
                      </Text>
                    </TitleBarSection>
                  </TitleBarContent>
                </TitleBar>
                {sidebarLinks.links && (
                  <Sidebar
                    as="nav"
                    aria-label={`${sidebarLinks.title} navigation`}
                  >
                    <Stack spaceBetween={1}>
                      <Heading4 as="header">{sidebarLinks.title}</Heading4>
                      <Contents>
                        <ContentsList>
                          {sidebarLinks.links.map(({ title: t, path }: any) => (
                            <ContentsListItem key={path}>
                              <ContentsLink
                                as={Link}
                                to={path}
                                activeClassName="active"
                              >
                                {t}
                              </ContentsLink>
                            </ContentsListItem>
                          ))}
                        </ContentsList>
                      </Contents>
                    </Stack>
                  </Sidebar>
                )}
                <Main id="main">{children}</Main>
                <Footer>
                  <Row>
                    <Col>
                      <SelectField
                        id="theme"
                        label="Theme"
                        value={selectedTheme}
                        characters={10}
                        onClick={e => e.stopPropagation()}
                        onValueChange={(newValue: string | undefined) => {
                          if (newValue) {
                            setSelectedTheme(newValue);
                            localStorage.setItem('themeSelection', newValue);
                          }
                        }}
                        options={[
                          { value: 'nebula-light', label: 'Nebula' },
                          { value: 'nebula-dark', label: 'Nebula Dark' },
                          { value: 'ovo-energy', label: 'OVO Energy' },
                          { value: 'kaluza', label: 'Kaluza' },
                        ]}
                      />
                    </Col>
                  </Row>
                </Footer>
              </AppContainer>
            </MDXProvider>
          </OverlayProvider>
        </ThemeProvider>
      </IconsProvider>
    </>
  );
};

export default Layout;
