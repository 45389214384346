var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import { VisuallyHidden } from '../VisuallyHidden';
import { NumberInput } from './NumberInput';
var CurrencyInput = forwardRef(function (_a, ref) {
    var id = _a.id, ariaDescribedBy = _a["aria-describedby"], ariaInvalid = _a["aria-invalid"], _b = _a.currency, currency = _b === void 0 ? 'GBP' : _b, _c = _a.inputMode, inputMode = _c === void 0 ? 'decimal' : _c, rest = __rest(_a, ["id", 'aria-describedby', 'aria-invalid', "currency", "inputMode"]);
    var visuallyHiddenLabelId = "".concat(id, "-describedby");
    var updatedAriaDescribedBy = "".concat(ariaDescribedBy ||
        '', " ").concat(visuallyHiddenLabelId).trim();
    var iconName = currency === 'GBP' ? 'pound' : currency === 'EUR' ? 'euro' : 'dollar';
    var currencyLabel = currency === 'GBP' ? 'pounds' : currency === 'EUR' ? 'euro' : 'dollar';
    return (React.createElement(React.Fragment, null,
        React.createElement(VisuallyHidden, { id: visuallyHiddenLabelId, "aria-hidden": "true" }, currencyLabel),
        React.createElement(NumberInput, __assign({ id: id, "aria-describedby": updatedAriaDescribedBy, "aria-invalid": ariaInvalid, iconLeft: iconName, inputMode: inputMode }, rest, { ref: ref }))));
});
export { CurrencyInput };
