import { Small, P, DataTable, Strong } from '@ovotech/nebula';
import React, { ReactNode } from 'react';
import { InlineCode } from './InlineCode';

type PropsData = {
  name: string;
  values: string[];
  preset?: string;
  required?: boolean;
  note?: ReactNode;
};

type Props = {
  propData: PropsData[];
  spread?: boolean;
};
export const PropsTable = ({ propData }: Props) => {
  return (
    <DataTable
      items={propData.map(
        ({ name, preset, required = false, values, note }, key) => {
          return [
            <P key={key}>
              <Strong>{name}</Strong>
              {required && ' (required)'}
              {note && (
                <span style={{ whiteSpace: 'normal', display: 'block' }}>
                  <Small>{note}</Small>
                </span>
              )}
            </P>,
            <P key={key} style={{ whiteSpace: 'normal' }}>
              {values.map((value: any, index: number) => (
                <InlineCode key={index} style={{ marginRight: '0.4rem' }}>
                  {value}
                </InlineCode>
              ))}
            </P>,
            <P key={key}>{preset && <InlineCode>{preset}</InlineCode>}</P>,
          ];
        },
      )}
      columnHeadings={['Name', 'Values', 'Default']}
    />
  );
};
