import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

export const HeroArt = styled(({ ...props }) => {
  const { colors } = useContext(ThemeContext);
  return (
    <svg height={760} viewBox="0 0 64 64" fill="none" {...props}>
      <circle
        r={7}
        transform="matrix(-1 0 0 1 32 32)"
        stroke={colors.primary}
        strokeWidth={0.5}
      />
      <circle
        r={15.5}
        transform="matrix(-1 0 0 1 32 32)"
        stroke={colors.border}
        strokeWidth={0.25}
      />
      <circle
        r={23.5}
        transform="matrix(-1 0 0 1 32 32)"
        stroke={colors.border}
        strokeWidth={0.25}
      />
      <circle
        r={31.5}
        transform="matrix(-1 0 0 1 32 32)"
        stroke={colors.border}
        strokeWidth={0.25}
      />
      <circle // inner
        cx={16.5}
        cy={32}
        r={1.5}
        fill={colors.border}
        transform="rotate(45, 32, 32)"
      />
      <circle // middle
        cx={8.5}
        cy={32}
        r={1.5}
        fill={colors.border}
        transform="rotate(-45, 32, 32)"
      />
      <circle // outer
        cx={0.5}
        cy={32}
        r={1.5}
        fill={colors.border}
        transform="rotate(-145, 32, 32)"
      />
      <circle // outer
        cx={0.5}
        cy={32}
        r={1.5}
        fill={colors.border}
        transform="rotate(25, 32, 32)"
      />
    </svg>
  );
})``;
