var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Gas = function (props) { return (React.createElement("svg", __assign({ viewBox: "0 0 16 16", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("path", { d: "M8 16c1.59-.001 3.116-.564 4.24-1.565 1.126-1.001 1.758-2.358 1.76-3.774C14 7 8 0 8 0S2 7 2 10.661c0 1.473.634 2.773 1.76 3.774 1.124 1 2.65 1.564 4.24 1.565zm-1.5-3.114C6.5 11.877 7.5 10.5 8 10c.5.5 1.5 2 1.5 2.886 0 .354-.158.693-.44.944a1.6 1.6 0 01-1.06.39 1.6 1.6 0 01-1.06-.39c-.282-.25-.44-.59-.44-.944zM8 2.5c1.349 1.65 4 5.5 4 8.161a3.227 3.227 0 01-.531 1.739C11 10 8 6.5 8 6.5S5 10 4.531 12.4A3.227 3.227 0 014 10.661C4 8 6.651 4.151 8 2.5z", fill: "currentColor" }))); };
