var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { useUniqueId } from '../../hooks';
import { remToNumber } from '../../utils';
import { Icon } from '../Icon';
var DisclosureButton = styled.button(function (_a) {
    var _b = _a.theme, mediaQueries = _b.mediaQueries, focusRing = _b.focusRing, text = _b.text, textLink = _b.textLink, transitions = _b.transitions, disclosure = _b.disclosure, responsiveFontSizes = _b.responsiveFontSizes, expanded = _a.expanded;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: none;\n    border: none;\n    border-radius: 0;\n    padding: 0;\n    margin: 0;\n    font-family: ", ";\n    font-size: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    text-decoration: underline;\n    white-space: nowrap;\n    color: ", ";\n    position: relative;\n    padding-left: ", "rem;\n\n    ", " {\n      font-size: ", ";\n      padding-left: ", "rem;\n    }\n\n    &:focus {\n      ", "\n    }\n\n    & > svg {\n      transition: ", ";\n      transform: ", ";\n      margin-right: ", ";\n      position: absolute;\n      top: 0;\n      bottom: 0;\n      left: 0;\n      margin-top: auto;\n      margin-bottom: auto;\n\n      ", " {\n        margin-right: ", ";\n      }\n    }\n  "], ["\n    background: none;\n    border: none;\n    border-radius: 0;\n    padding: 0;\n    margin: 0;\n    font-family: ", ";\n    font-size: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    text-decoration: underline;\n    white-space: nowrap;\n    color: ", ";\n    position: relative;\n    padding-left: ", "rem;\n\n    ", " {\n      font-size: ", ";\n      padding-left: ", "rem;\n    }\n\n    &:focus {\n      ", "\n    }\n\n    & > svg {\n      transition: ", ";\n      transform: ", ";\n      margin-right: ", ";\n      position: absolute;\n      top: 0;\n      bottom: 0;\n      left: 0;\n      margin-top: auto;\n      margin-bottom: auto;\n\n      ", " {\n        margin-right: ", ";\n      }\n    }\n  "])), text.fontFamily, text.p.fontSize[0], text.lineHeight, textLink.fontWeight, textLink.color, remToNumber(responsiveFontSizes.body[0]) +
        remToNumber(disclosure.iconGap[0]), mediaQueries.smallAndUp, text.p.fontSize[1], remToNumber(responsiveFontSizes.body[1]) +
        remToNumber(disclosure.iconGap[1]), css(focusRing), transitions.standard, expanded ? 'rotate(0)' : 'rotate(-90deg)', disclosure.iconGap[0], mediaQueries.smallAndUp, disclosure.iconGap[1]);
});
var LineIndent = styled.div(function (_a) {
    var _b = _a.theme, disclosure = _b.disclosure, mediaQueries = _b.mediaQueries, responsiveFontSizes = _b.responsiveFontSizes;
    var lineOffset = function (fontSize) {
        return "".concat(remToNumber(fontSize) / 2 -
            remToNumber(disclosure.lineIndent.width) / 2, "rem");
    };
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      position: absolute;\n      top: 0;\n      bottom: 0;\n      width: ", ";\n      border-radius: ", ";\n      left: ", ";\n      background-color: ", ";\n      ", " {\n        left: ", ";\n      }\n    "], ["\n      position: absolute;\n      top: 0;\n      bottom: 0;\n      width: ", ";\n      border-radius: ", ";\n      left: ", ";\n      background-color: ", ";\n      ", " {\n        left: ", ";\n      }\n    "])), disclosure.lineIndent.width, disclosure.lineIndent.borderRadius, lineOffset(responsiveFontSizes.body[0]), disclosure.lineIndent.backgroundColor, mediaQueries.smallAndUp, lineOffset(responsiveFontSizes.body[1]));
});
var DisclosurePanel = styled.div(function (_a) {
    var _b = _a.theme, mediaQueries = _b.mediaQueries, disclosure = _b.disclosure, responsiveFontSizes = _b.responsiveFontSizes;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: relative;\n    margin-top: ", ";\n    padding-left: ", "rem;\n\n    ", " {\n      margin-top: ", ";\n      padding-left: ", "rem;\n    }\n  "], ["\n    position: relative;\n    margin-top: ", ";\n    padding-left: ", "rem;\n\n    ", " {\n      margin-top: ", ";\n      padding-left: ", "rem;\n    }\n  "])), disclosure.panelGap[0], remToNumber(responsiveFontSizes.body[0]) +
        remToNumber(disclosure.iconGap[0]), mediaQueries.smallAndUp, disclosure.panelGap[1], remToNumber(responsiveFontSizes.body[1]) +
        remToNumber(disclosure.iconGap[1]));
});
export var Disclosure = forwardRef(function (_a, ref) {
    var children = _a.children, title = _a.title, _b = _a.expanded, expanded = _b === void 0 ? false : _b, onToggle = _a.onToggle, rest = __rest(_a, ["children", "title", "expanded", "onToggle"]);
    var id = useUniqueId();
    var _c = useState(expanded), isExpanded = _c[0], setIsExpanded = _c[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(DisclosureButton, __assign({ onClick: function () {
                setIsExpanded(!isExpanded);
                onToggle && onToggle(!isExpanded);
            }, "aria-expanded": isExpanded, "aria-controls": id, expanded: isExpanded, type: "button" }, rest, { ref: ref }),
            React.createElement(Icon, { name: "caret-down" }),
            title),
        isExpanded && (React.createElement(DisclosurePanel, { id: id },
            React.createElement(LineIndent, { "aria-hidden": "true" }),
            children))));
});
var templateObject_1, templateObject_2, templateObject_3;
