import { Heading1, pxToNumber, remToNumber } from '@ovotech/nebula';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Large } from '../components';
import { useTheme } from '../hooks';
import { HeroArt } from './HeroArt';

export const Hero = styled.div(
  ({ theme: { colors, mediaQueries, space, breakpoints } }) => `
      padding-top: ${space[10]};
      padding-bottom: ${space[10]};
      background: ${colors.canvasMuted};
      position: relative;
      overflow: hidden;

      ${mediaQueries.smallAndUp} {
        padding-top: ${remToNumber(space[12]) + remToNumber(space[4])}rem;
        padding-bottom: ${remToNumber(space[12]) + remToNumber(space[4])}rem;
      }

      ${HeroArt} {
        position: absolute;
        left: 50%;
        top: -40%;
        width: ${pxToNumber(breakpoints.large) / 2}px;
        opacity: 0.1;

        ${mediaQueries.mediumAndUp} {
          opacity: 1;
        }
      }
    `,
);

const HeroOVO = styled(Hero)(
  ({ theme: { colors, mediaQueries } }) => `
      background-image: url(/hero-ovo-small.png);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      ${mediaQueries.mediumAndUp} {
        background-image: url(/hero-ovo.png);
      }

      ${HeroArt} {
        display: none;
      }

      ${Large}, ${Heading1} {
        color: ${colors.primaryContrast};
      }
    `,
);

export const CustomHero = ({ children }: { children: ReactNode }) => {
  // @ts-ignore
  const { isOVO } = useTheme();

  return isOVO ? <HeroOVO>{children}</HeroOVO> : <Hero>{children}</Hero>;
};
