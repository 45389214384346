var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext, forwardRef, useState, } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { Heading2 } from '../Heading';
import { Icon } from '../Icon';
import { VisuallyHidden } from '../VisuallyHidden';
var MarginBottom = styled.div(function (_a) {
    var space = _a.theme.space, noMargin = _a.noMargin;
    return noMargin ? null : "margin-bottom: ".concat(space[4], ";");
});
var FocusWrapper = styled.div(function (_a) {
    var _b = _a.theme, focusRing = _b.focusRing, notification = _b.notification;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    &:focus {\n      ", "\n    }\n    border-radius: ", ";\n  "], ["\n    &:focus {\n      ", "\n    }\n    border-radius: ", ";\n  "])), focusRing, notification.borderRadius);
});
var Notification = forwardRef(function (_a, ref) {
    var id = _a.id, title = _a.title, children = _a.children, noMargin = _a.noMargin, variant = _a.variant, dismissable = _a.dismissable, onDismiss = _a.onDismiss, rest = __rest(_a, ["id", "title", "children", "noMargin", "variant", "dismissable", "onDismiss"]);
    var _b = useState(false), dismissed = _b[0], setDismissed = _b[1];
    return dismissed ? null : (React.createElement(FocusWrapper, __assign({ tabIndex: -1, "aria-labelledby": title && children ? "".concat(id, "-title") : undefined, "data-testid": "nb-notification-focus-wrapper", id: id }, rest, { ref: ref }), children ? (React.createElement(MarginBottom, { noMargin: noMargin },
        React.createElement(NotificationBox, { variant: variant, "data-testid": "nb-notification-box" },
            dismissable ? (React.createElement(DismissButton, { onClick: function () {
                    setDismissed(true);
                    if (onDismiss) {
                        onDismiss();
                    }
                }, "data-testid": "nb-notification-dismiss" })) : null,
            React.createElement("div", null,
                title ? React.createElement(Heading2, { id: "".concat(id, "-title") }, title) : null,
                children)))) : null));
});
var NotificationBox = styled.div(function (_a) {
    var _b = _a.theme, mediaQueries = _b.mediaQueries, notification = _b.notification, _c = _a.variant, variant = _c === void 0 ? 'info' : _c;
    // @ts-ignore to support additional palettes.
    var currentVariant = notification.variants[variant];
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: ", " solid ", ";\n    background-color: ", ";\n    border-radius: ", ";\n    position: relative;\n\n    padding: ", ";\n    ", " {\n      padding: ", ";\n    }\n  "], ["\n    border: ", " solid ", ";\n    background-color: ", ";\n    border-radius: ", ";\n    position: relative;\n\n    padding: ", ";\n    ", " {\n      padding: ", ";\n    }\n  "])), notification.borderWidth, currentVariant.borderColor, currentVariant.background, notification.borderRadius, notification.padding[0], mediaQueries.smallAndUp, notification.padding[1]);
});
var StyledDismissButton = styled.button(function (_a) {
    var _b = _a.theme, focusRing = _b.focusRing, notification = _b.notification;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background: none;\n    border: none;\n    padding: 0;\n    width: ", ";\n    height: ", ";\n    position: absolute;\n    color: ", ";\n    top: 0;\n    right: 0;\n\n    &:focus {\n      ", "\n    }\n  "], ["\n    background: none;\n    border: none;\n    padding: 0;\n    width: ", ";\n    height: ", ";\n    position: absolute;\n    color: ", ";\n    top: 0;\n    right: 0;\n\n    &:focus {\n      ", "\n    }\n  "])), notification.dismiss.touchSize, notification.dismiss.touchSize, notification.dismiss.color, focusRing);
});
var DismissButton = function (props) {
    var theme = useContext(ThemeContext);
    return (React.createElement(StyledDismissButton, __assign({}, props),
        React.createElement(Icon, { name: theme.notification.dismiss.icon, size: theme.notification.dismiss.size }),
        React.createElement(VisuallyHidden, null, "Dismiss")));
};
var ErrorNotification = forwardRef(function (_a, ref) {
    var _b = _a.title, title = _b === void 0 ? React.createElement(React.Fragment, null, "Oops, there\u2019s a problem\u2026") : _b, rest = __rest(_a, ["title"]);
    return React.createElement(Notification, __assign({ title: title, variant: "error", ref: ref }, rest));
});
var InfoNotification = forwardRef(function (props, ref) { return (React.createElement(Notification, __assign({ variant: "info", ref: ref }, props))); });
var SuccessNotification = forwardRef(function (props, ref) { return (React.createElement(Notification, __assign({ variant: "success", ref: ref }, props))); });
var WarningNotification = forwardRef(function (props, ref) { return (React.createElement(Notification, __assign({ variant: "warning", ref: ref }, props))); });
export { NotificationBox, FocusWrapper, Notification, ErrorNotification, InfoNotification, SuccessNotification, WarningNotification, };
var templateObject_1, templateObject_2, templateObject_3;
