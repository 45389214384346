import {
  Disclosure,
  Card,
  getErrorMessage,
  createTheme,
} from '@ovotech/nebula';
import * as components from '@ovotech/nebula/components';
import dracula from 'prism-react-renderer/themes/dracula';
import React, { useState } from 'react';

import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import styled, { ThemeProvider } from 'styled-components';
import { Block } from './Block';

const CodeBlockPanel = styled.div(
  ({ theme: { space, radii } }) => `
    background: ${dracula.plain.backgroundColor};
    padding: ${space[3]} ${space[6]};
    border-radius: ${radii.standard};
  `,
);

const StyledPreview = styled.div`
  white-space: normal;
`;

const CodeArea = styled.div(
  ({ theme: { card, colors, borderWidths, mediaQueries } }) => `
    background: ${colors.canvasMuted};
    border-top: ${borderWidths.standard} solid ${colors.borderMuted};
    padding: ${card.padding[0]};
    margin: ${card.padding[0]} -${card.padding[0]} -${card.padding[0]};
    border-bottom-right-radius: ${card.borderRadius};
    border-bottom-left-radius: ${card.borderRadius};

    ${mediaQueries.smallAndUp} {
      padding: ${card.padding[1]};
      margin: ${card.padding[1]} -${card.padding[1]} -${card.padding[1]};
    }
  `,
);

const CodeOnlyArea = styled(CodeBlockPanel)(
  ({ theme: { card, mediaQueries } }) => `
    padding: ${card.padding[0]};
    margin: -${card.padding[0]};
    border-bottom-right-radius: ${card.borderRadius};
    border-bottom-left-radius: ${card.borderRadius};

    ${mediaQueries.smallAndUp} {
      padding: ${card.padding[1]};
      margin: -${card.padding[1]};
    }
  `,
);

const StyledLiveError = styled(LiveError)(
  ({ theme: { fonts, responsiveFontSizes, mediaQueries, space } }) => `
    font-family: ${fonts.monospace};
    font-size: ${responsiveFontSizes.small[0]};
    margin-top: ${space[2]};
    overflow-x: scroll;

    ${mediaQueries.smallAndUp} {
      font-size: ${responsiveFontSizes.small[1]};
    }
  `,
);

export const PreviewPanel = ({
  code = '',
  children,
  scope = {},
  codeOnly = false,
}: any) => {
  const scopeWithComponents = {
    ...components,
    Block,
    ThemeProvider,
    createTheme,
    useState,
    getErrorMessage,
    ...scope,
  };
  const disabled = !!children || codeOnly;

  return (
    <Card inline>
      <LiveProvider code={code} scope={scopeWithComponents} theme={dracula}>
        {children || codeOnly ? (
          children
        ) : (
          <StyledPreview>
            <LivePreview />
          </StyledPreview>
        )}
        {codeOnly ? (
          <CodeOnlyArea>
            <LiveEditor style={{ margin: '-10px', fontSize: '16px' }} />
          </CodeOnlyArea>
        ) : (
          <CodeArea>
            <Disclosure title={disabled ? 'View code' : `Edit live code`}>
              <CodeBlockPanel>
                <LiveEditor style={{ margin: '-10px', fontSize: '16px' }} />
              </CodeBlockPanel>
              {!disabled ? <StyledLiveError /> : null}
            </Disclosure>
          </CodeArea>
        )}
      </LiveProvider>
    </Card>
  );
};
