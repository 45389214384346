var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { remToNumber, callAll } from '../../utils';
import { Icon } from '../Icon';
import { StyledInput } from './Input';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var StyledIcon = styled(Icon)(function (_a) {
    var input = _a.theme.input;
    var distance = (remToNumber(input.height) - remToNumber(input.icon.size)) / 2;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    top: ", "rem;\n    right: ", "rem;\n    width: ", ";\n\n    path {\n      fill: ", ";\n    }\n\n    &[data-error='true'] {\n      path {\n        fill: ", ";\n      }\n    }\n  "], ["\n    position: absolute;\n    top: ", "rem;\n    right: ", "rem;\n    width: ", ";\n\n    path {\n      fill: ", ";\n    }\n\n    &[data-error='true'] {\n      path {\n        fill: ", ";\n      }\n    }\n  "])), distance, distance, input.icon.size, input.color, input.error.color);
});
var StyledSelect = styled.select(function (_a) {
    var input = _a.theme.input;
    return "\n    appearance: none;\n    padding-right: ".concat(input.height, ";\n  ");
});
var SelectInput = forwardRef(function (_a, ref) {
    var options = _a.options, children = _a.children, onChange = _a.onChange, onValueChange = _a.onValueChange, ariaInvalid = _a["aria-invalid"], rest = __rest(_a, ["options", "children", "onChange", "onValueChange", 'aria-invalid']);
    var input = useContext(ThemeContext).input;
    return (React.createElement(Wrapper, null,
        React.createElement(StyledInput, __assign({}, rest, { as: StyledSelect, "aria-invalid": ariaInvalid, onChange: callAll(onValueChange &&
                (function (event) {
                    return onValueChange(event.currentTarget.value);
                }), onChange), ref: ref }),
            options
                ? options.map(function (_a) {
                    var value = _a.value, label = _a.label;
                    return (React.createElement("option", { key: value, value: value }, label));
                })
                : null,
            children),
        React.createElement(StyledIcon, { name: "chevron-down", size: remToNumber(input.icon.size) * 10, color: input.color, "data-error": ariaInvalid, pointerEvents: "none" })));
});
export { SelectInput };
var templateObject_1, templateObject_2;
