var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Interval = function (props) { return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }, props),
    React.createElement("path", { fill: "currentColor", d: "M2.6 8.8c5.644-4.233 13.156-4.233 18.8 0a1 1 0 0 0 1.2-1.6c-6.356-4.767-14.844-4.767-21.2 0a1 1 0 1 0 1.2 1.6Zm10.153 11.368a1 1 0 1 0-1.486-1.338l-.01.01a1 1 0 1 0 1.486 1.34l.01-.012ZM18.4 12.8c-3.644-2.733-9.156-2.733-12.8 0a1 1 0 1 1-1.2-1.6c4.356-3.267 10.844-3.267 15.2 0a1 1 0 0 1-1.2 1.6Zm-9.372 3.55c1.927-1.2 4.017-1.2 5.944-.001a1 1 0 0 0 1.057-1.698c-2.574-1.602-5.483-1.601-8.057 0a1 1 0 1 0 1.056 1.698Z" }))); };
