var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var Heading1 = styled.h1(function (_a) {
    var _b = _a.theme, heading = _b.heading, mediaQueries = _b.mediaQueries;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n\n    font-size: ", ";\n    margin-top: ", ";\n    margin-bottom: ", ";\n\n    ", " {\n      font-size: ", ";\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "], ["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n\n    font-size: ", ";\n    margin-top: ", ";\n    margin-bottom: ", ";\n\n    ", " {\n      font-size: ", ";\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "])), heading.fontFamily, heading.lineHeight, heading.heading1.fontWeight || heading.fontWeight, heading.color, heading.heading1.fontSize[0], heading.heading1.marginTop[0], heading.heading1.marginBottom[0], mediaQueries.smallAndUp, heading.heading1.fontSize[1], heading.heading1.marginTop[1], heading.heading1.marginBottom[1]);
});
export var Heading2 = styled.h2(function (_a) {
    var _b = _a.theme, heading = _b.heading, mediaQueries = _b.mediaQueries;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n\n    font-size: ", ";\n    margin-top: ", ";\n    margin-bottom: ", ";\n\n    ", " {\n      font-size: ", ";\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    ", " + &,\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "], ["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n\n    font-size: ", ";\n    margin-top: ", ";\n    margin-bottom: ", ";\n\n    ", " {\n      font-size: ", ";\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    ", " + &,\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "])), heading.fontFamily, heading.lineHeight, heading.heading2.fontWeight || heading.fontWeight, heading.color, heading.heading2.fontSize[0], heading.heading2.marginTop[0], heading.heading2.marginBottom[0], mediaQueries.smallAndUp, heading.heading2.fontSize[1], heading.heading2.marginTop[1], heading.heading2.marginBottom[1], Heading1);
});
export var Heading3 = styled.h3(function (_a) {
    var _b = _a.theme, heading = _b.heading, mediaQueries = _b.mediaQueries;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n\n    font-size: ", ";\n    margin-top: ", ";\n    margin-bottom: ", ";\n\n    ", " {\n      font-size: ", ";\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    ", " + &,\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "], ["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n\n    font-size: ", ";\n    margin-top: ", ";\n    margin-bottom: ", ";\n\n    ", " {\n      font-size: ", ";\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    ", " + &,\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "])), heading.fontFamily, heading.lineHeight, heading.heading3.fontWeight || heading.fontWeight, heading.color, heading.heading3.fontSize[0], heading.heading3.marginTop[0], heading.heading3.marginBottom[0], mediaQueries.smallAndUp, heading.heading3.fontSize[1], heading.heading3.marginTop[1], heading.heading3.marginBottom[1], Heading2);
});
export var Heading4 = styled.h4(function (_a) {
    var _b = _a.theme, heading = _b.heading, mediaQueries = _b.mediaQueries;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n\n    font-size: ", ";\n    margin-top: ", ";\n    margin-bottom: ", ";\n\n    ", " {\n      font-size: ", ";\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    ", " + &,\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "], ["\n    font-family: ", ";\n    line-height: ", ";\n    font-weight: ", ";\n    color: ", ";\n\n    font-size: ", ";\n    margin-top: ", ";\n    margin-bottom: ", ";\n\n    ", " {\n      font-size: ", ";\n      margin-top: ", ";\n      margin-bottom: ", ";\n    }\n\n    ", " + &,\n    &:first-child {\n      margin-top: 0;\n    }\n    &:last-child {\n      margin-bottom: 0;\n    }\n  "])), heading.fontFamily, heading.lineHeight, heading.heading4.fontWeight || heading.fontWeight, heading.color, heading.heading4.fontSize[0], heading.heading4.marginTop[0], heading.heading4.marginBottom[0], mediaQueries.smallAndUp, heading.heading4.fontSize[1], heading.heading4.marginTop[1], heading.heading4.marginBottom[1], Heading3);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
