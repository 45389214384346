var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, } from 'react';
import styled, { css } from 'styled-components';
import { isExternalLink } from '../../utils';
import { Icon } from '../Icon';
import { VisuallyHidden } from '../VisuallyHidden';
var StyledA = styled.a(function (_a) {
    var _b = _a.theme, textLink = _b.textLink, focusRing = _b.focusRing;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    text-decoration: none;\n    font-weight: ", ";\n    color: ", ";\n\n    &:hover {\n      color: ", ";\n    }\n    &:focus {\n      ", "\n    }\n  "], ["\n    text-decoration: none;\n    font-weight: ", ";\n    color: ", ";\n\n    &:hover {\n      color: ", ";\n    }\n    &:focus {\n      ", "\n    }\n  "])), textLink.fontWeight, textLink.color, textLink.hover, focusRing);
});
var StyledSpan = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-decoration: underline;\n"], ["\n  text-decoration: underline;\n"])));
var TextLink = forwardRef(function (_a, ref) {
    var href = _a.href, children = _a.children, _b = _a.opensInNewWindow, opensInNewWindow = _b === void 0 ? false : _b, rel = _a.rel, target = _a.target, rest = __rest(_a, ["href", "children", "opensInNewWindow", "rel", "target"]);
    var updatedRel = isExternalLink(href)
        ? "".concat(rel || '', " noopener").trim()
        : rel;
    var updatedTarget = opensInNewWindow ? '_blank' : target;
    return (React.createElement(StyledA, __assign({ href: href, rel: updatedRel, target: updatedTarget }, rest, { ref: ref }),
        React.createElement(StyledSpan, null, children),
        opensInNewWindow && (React.createElement(React.Fragment, null,
            React.createElement(VisuallyHidden, null, " (opens in new window)"),
            "\u00A0",
            React.createElement(Icon, { name: "new-window", size: "0.8em" })))));
});
export { TextLink };
var templateObject_1, templateObject_2;
