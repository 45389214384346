var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Children, cloneElement } from 'react';
import styled, { css } from 'styled-components';
import { callAll } from '../../utils';
import { Tab } from './Tab';
export var TabList = styled(function (_a) {
    var children = _a.children, fullWidth = _a.fullWidth, setActiveTab = _a.setActiveTab, activeTab = _a.activeTab, onKeyDown = _a.onKeyDown, tabRefs = _a.tabRefs, tabIds = _a.tabIds, rest = __rest(_a, ["children", "fullWidth", "setActiveTab", "activeTab", "onKeyDown", "tabRefs", "tabIds"]);
    return (React.createElement("ul", __assign({ role: "tablist" }, rest), Children.map(children, function (child, i) {
        return cloneElement(child, {
            fullWidth: fullWidth,
            id: tabIds[i],
            onKeyDown: onKeyDown,
            ref: tabRefs[i],
            onClick: callAll(function () { return setActiveTab && setActiveTab(i); }, child.props.onClick),
            'aria-selected': activeTab === i,
            tabIndex: activeTab === i ? 0 : -1,
            active: activeTab === i,
        });
    })));
})(function (_a) {
    var _b = _a.theme, card = _b.card, mediaQueries = _b.mediaQueries, fullWidth = _a.fullWidth;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    margin: -", ";\n\n    ", ";\n\n    ", " + ", " {\n      margin-left: -", ";\n    }\n  "], ["\n    display: flex;\n    margin: -", ";\n\n    ", ";\n\n    ", " + ", " {\n      margin-left: -", ";\n    }\n  "])), card.borderWidth, fullWidth === 'small'
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            ", " {\n              display: inline-flex;\n            }\n          "], ["\n            ", " {\n              display: inline-flex;\n            }\n          "])), mediaQueries.smallAndUp) : fullWidth === 'never'
        ? css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            display: inline-flex;\n          "], ["\n            display: inline-flex;\n          "]))) : '', Tab, Tab, card.borderWidth);
});
var templateObject_1, templateObject_2, templateObject_3;
