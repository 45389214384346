var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useContext } from 'react';
import { CaretDown, CaretRight, ChevronLeft, ChevronRight, ChevronUp, ChevronDown, Cross, Check, Euro, Pound, Dollar, NewWindow, Gas, Electricity, Link, Download, Plus, Minus, Anytime, EveningAndWeekend, Heating, Interval, Shoulder, Solar, Peak, Offpeak, } from './icons';
var defaultIcons = {
    'caret-down': CaretDown,
    'caret-right': CaretRight,
    'chevron-left': ChevronLeft,
    'chevron-right': ChevronRight,
    'chevron-up': ChevronUp,
    'chevron-down': ChevronDown,
    cross: Cross,
    check: Check,
    euro: Euro,
    pound: Pound,
    dollar: Dollar,
    'new-window': NewWindow,
    gas: Gas,
    electricity: Electricity,
    link: Link,
    download: Download,
    plus: Plus,
    minus: Minus,
    anytime: Anytime,
    'evening-and-weekend': EveningAndWeekend,
    heating: Heating,
    interval: Interval,
    smart: Interval,
    shoulder: Shoulder,
    solar: Solar,
    peak: Peak,
    offpeak: Offpeak,
};
var IconsContext = createContext(null);
function useIcons() {
    var icons = useContext(IconsContext) || defaultIcons;
    return icons;
}
function IconsProvider(_a) {
    var children = _a.children, _b = _a.icons, icons = _b === void 0 ? {} : _b;
    var baseIcons = useContext(IconsContext) || defaultIcons;
    var mergedIcons = __assign(__assign({}, baseIcons), icons);
    return (React.createElement(IconsContext.Provider, { value: mergedIcons }, children));
}
export { useIcons, IconsProvider, defaultIcons };
